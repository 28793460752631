import { actionCreatorFactory } from 'typescript-fsa'

const actionCreator = actionCreatorFactory()
export const Actions = {
  fetch: actionCreator.async<{}, {}>('FETCH_FORECAST_LIST'),
  favorite: actionCreator.async<{ id: string }, { id: string }>(
    'FAVORITE_FORECAST'
  ),
  unfavorite: actionCreator.async<{ id: string }, { id: string }>(
    'UNFAVORITE_FORECAST'
  ),
}
