export const HOME = '/'
export const EMOTION_HOME = '/emotions'
export const SETTING_HOME = '/settings'

export const SIGNIN = '/signin'
export const SIGNUP = '/signup'
export const SIGNUP_CONFIRM = '/signup/confirm'
export const PASSWORD_FORGOT = '/forgot_password'
export const RESET_PASSWORD = '/reset_password'
export const VERIFY_EMAIL = '/verify_email'
