import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {
  MutationFavoriteForecast,
  MutationUnfavoriteForecast,
} from '../graphql'

export const favoriteForecast = async (variables: { id: string }) => {
  const response: any = await API.graphql({
    query: MutationFavoriteForecast,
    variables,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return { id: response.data.favoriteForecast.id as string }
}

export const unfavoriteForecast = async (variables: { id: string }) => {
  const response: any = await API.graphql({
    query: MutationUnfavoriteForecast,
    variables,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return { id: response.data.unfavoriteForecast.id as string }
}
