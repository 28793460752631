import { Auth } from 'aws-amplify'
import { IVerifyEmail } from '../../interface'
import localize from './localize'

const verifyEmail = async (data: IVerifyEmail) => {
  try {
    const cognitoUser = await Auth.currentUserPoolUser()
    await Auth.verifyUserAttributeSubmit(cognitoUser, 'email', data.code)

    await Auth.signOut()
    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default verifyEmail
