import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import { IconButton } from '@material-ui/core'
import { IForecast } from '@/stores/emotion/interface'
import { format } from 'date-fns'
import { Action } from 'typescript-fsa'

interface IIOwnProps {
  data: IForecast
  actionFavorite: (id: string) => Action<{ id: string }>
  actionUnfavorite: (id: string) => Action<{ id: string }>
}

type Props = IIOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  const [favorite, setFavorite] = React.useState<boolean>(props.data.isFavorite)

  const handleFavorite = (id: string) => {
    const updateValue = !favorite
    setFavorite(updateValue)

    if (updateValue) {
      props.actionFavorite(id)
    } else {
      props.actionUnfavorite(id)
    }
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.weathers}>
            <div className={classes.time}>
              {format(new Date(props.data.settedAt), 'aa hh:mm')}
            </div>
            <div className={classes.weather}>
              {constants.getWeatherLabelByValue(props.data.weather)}
            </div>
          </div>
          <div className={classes.monthDay}>
            <div className={classes.month}>
              {new Date(props.data.settedAt).getMonth() + 1}月
            </div>
            <div className={classes.day}>
              {new Date(props.data.settedAt).getDate()}日
            </div>
          </div>
        </div>

        <div className={classes.body}>
          <div className={classes.roughContainer}>
            <div>
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/assets/svg/face_${constants.getFaceNumberByValue(
                  props.data.value
                )}.svg`}
                alt={'face'}
              />
            </div>
            <div className={classes.wellBeing}>
              <div className={classes.wellBeingLabel}>ウェルビーイング指数</div>
              <div className={classes.wellBeingValue}>{props.data.value}%</div>
            </div>
            <div>
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/assets/svg/weather_${props.data.weather.toLowerCase()}.svg`}
                alt={props.data.weather.toLowerCase()}
              />
            </div>
          </div>
          <div className={classes.description}>{props.data.reason}</div>
        </div>
        <div className={`${classes.footer} ${favorite ? 'active' : ''}`}>
          お気に入り
          <IconButton
            onClick={() => {
              handleFavorite(props.data.id)
            }}
          >
            {favorite ? (
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.34354 11.692C6.86854 12.1233 6.13729 12.1233 5.66229 11.6858L5.59354 11.6233C2.31229 8.65451 0.168541 6.71076 0.249791 4.28576C0.287291 3.22326 0.83104 2.20451 1.71229 1.60451C3.36229 0.47951 5.39979 1.00451 6.49979 2.29201C7.59979 1.00451 9.63729 0.47326 11.2873 1.60451C12.1685 2.20451 12.7123 3.22326 12.7498 4.28576C12.8373 6.71076 10.6873 8.65451 7.40604 11.6358L7.34354 11.692Z"
                  fill="#B073E5"
                />
              </svg>
            ) : (
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5 2.07187C7.18125 1.27187 8.225 0.765625 9.3125 0.765625C11.2375 0.765625 12.75 2.27813 12.75 4.20312C12.75 6.56369 10.6285 8.48747 7.41417 11.4022L7.40625 11.4094L6.5 12.2344L5.59375 11.4156L5.56908 11.3932C2.36415 8.48078 0.25 6.55958 0.25 4.20312C0.25 2.27813 1.7625 0.765625 3.6875 0.765625C4.775 0.765625 5.81875 1.27187 6.5 2.07187ZM6.5 10.5469L6.5625 10.4844C9.5375 7.79063 11.5 6.00938 11.5 4.20313C11.5 2.95313 10.5625 2.01563 9.3125 2.01563C8.35 2.01563 7.4125 2.63438 7.0875 3.49063H5.91875C5.5875 2.63438 4.65 2.01563 3.6875 2.01563C2.4375 2.01563 1.5 2.95313 1.5 4.20313C1.5 6.00938 3.4625 7.79063 6.4375 10.4844L6.5 10.5469Z"
                  fill="#B0A1CB"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      marginBottom: 20,
      borderRadius: 15,
      background: '#fff',
      boxShadow: '5px 5px 15px rgba(188, 109, 236, 0.15)',
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 20,
    },

    weathers: {},

    time: {
      fontSize: 12,
      color: constants.COLOR_MAIN_LIGHT,
    },
    weather: {
      fontSize: 20,
    },

    monthDay: {
      textAlign: 'right',
    },
    month: {
      fontSize: 12,
    },

    day: {
      fontSize: 18,
    },

    body: {
      padding: '0 20px 20px',
    },
    roughContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    wellBeing: {
      width: '100%',
      padding: '0 10px',
    },

    wellBeingLabel: {
      fontSize: 12,
    },
    wellBeingValue: {
      fontSize: 16,
    },

    description: {
      fontSize: 12,
      marginTop: 15,
    },

    footer: {
      borderTop: `1px solid #E1DEEB`,
      padding: 15,
      textAlign: 'right',
      fontSize: 12,
      color: constants.COLOR_MAIN_LIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  })

export default withStyles(useStyles)(Index)
