const dict = {
  'Enter your code': '確認コードを入力してください',
  'Enter your password': 'パスワードを入力してください',
  'Enter your username': 'ユーザー名を入力してください',
  'Forget your password? ': 'パスワードをお忘れの方 ',
  'Have an account? ': 'アカウント登録済みの方 ',
  'Incorrect username or password.': 'ユーザー名またはパスワードが異なります',
  'Sign in to your account': 'サインイン',
  'User does not exist.': 'ユーザーが存在しません',
  'User is not confirmed.': 'まだ認証されていないユーザーです',
  'Username cannot be empty': 'ユーザー名は必須入力です',
  'An account with the given email already exists.':
    '既に登録されているメールアドレスです',
  'Username/client id combination not found.': 'ユーザー名が見つかりません',
  'Password attempts exceeded':
    'パスワードの試行回数を超えました。しばらくしてからもう一度お試しください',
  'Invalid verification code provided, please try again.':
    '認証コードが正しくありません。確認してもう一度お試しください',
  'Attempt limit exceeded, please try after some time.':
    '試行回数を超えました。しばらくしてからもう一度お試しください',
  'Cannot reset password for the user as there is no registered/verified email or phone_number':
    'メールアドレスが認証されていないため、パスワードリセット出来ません',
}

const localize = (message) => {
  const result = dict[message] || message || 'エラーが発生しました[L0001]'
  return result
}

export default localize
