import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { RootStateType } from '@/stores'
import { Actions } from './action'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchEmotions: () => dispatch(Actions.fetch.started({})),
    favorite: (id: string) => dispatch(Actions.favorite.started({ id })),
    unfavorite: (id: string) => dispatch(Actions.unfavorite.started({ id })),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    emotion: { ...state.emotion },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
