import { Auth } from 'aws-amplify'
import { IInputProfile } from '../../interface'
import localize from './localize'

const updateProfile = async (user: IInputProfile) => {
  try {
    const cognitoUser = await Auth.currentUserPoolUser()
    const oldEmail = cognitoUser.attributes.email

    await Auth.updateUserAttributes(cognitoUser, {
      nickname: user.username,
      email: user.email,
    })

    let emailVerified = true
    if (oldEmail !== user.email) {
      emailVerified = false
    }

    return {
      ...user,
      emailVerified,
    }
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default updateProfile
