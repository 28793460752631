import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import UsefulContainer from '@/pages/components/UsefulContainer'
import * as constants from '@/assets/constants'
import { IconButton } from '@material-ui/core'
import Graph, { IGraphData } from '@/pages/homes/components/Graph'
import { connector, ContainerProps } from './index.container'
import { format, subDays, addDays, differenceInDays } from 'date-fns'
import { IForecast } from '@/stores/emotion/interface'

type Props = ContainerProps & WithStyles<typeof useStyles>
const Index: React.FC<Props> = (props) => {
  const [baseDate, setBaseDate] = React.useState(new Date())
  const [data, setData] = React.useState<IGraphData[]>([])

  React.useEffect(() => {
    if (props.emotion.all.length === 0) {
      props.fetchEmotions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (props.emotion.all.length > 0) {
      setData(assortedData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.emotion])

  const { classes } = props

  const prevDays = () => {
    setBaseDate(subDays(baseDate, 7))
  }

  const nextDays = () => {
    setBaseDate(addDays(baseDate, 7))
  }

  React.useEffect(() => {
    setData(assortedData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseDate])

  const assortedData = () => {
    const result: IGraphData[] = []
    for (let i = 0; i < 7; i++) {
      const targetDate = subDays(baseDate, i)
      const targetResults: IForecast[] = []

      props.emotion.all.forEach((d) => {
        if (
          format(new Date(d.settedAt), 'yyy-MM-dd') ===
          format(targetDate, 'yyyy-MM-dd')
        ) {
          targetResults.push(d)
        }
      })

      if (targetResults.length > 0) {
        targetResults.sort(function (a, b) {
          if (a.updatedAt > b.updatedAt) return -1
          if (a.updatedAt < b.updatedAt) return 1
          return 0
        })
        result.push({
          weekday: constants.getWeekday(
            new Date(targetResults[0].settedAt).getDay()
          ),
          value: targetResults[0].value,
          weather: targetResults[0].weather,
        })
      } else {
        result.push({
          weekday: constants.getWeekday(subDays(baseDate, i).getDay()),
          value: null,
          weather: null,
        })
      }
    }

    return result.reverse()
  }

  const averageWellbeing = () => {
    let total = 0
    let count = 0
    data.forEach((d) => {
      if (d.value) {
        total += d.value || 0
        count++
      }
    })

    if (count > 0) {
      return Math.floor(total / count)
    } else {
      return 0
    }
  }

  return (
    <React.Fragment>
      <UsefulContainer>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.title}>
              <span>{props.user.username}さんの</span>
              <span>週間天気</span>
            </div>
            <div className={classes.wellBeing}>
              <div className={classes.wellBeingLabel}>
                <span>ウェルビーイング</span>
                <span>指数</span>
              </div>
              <div className={classes.wellBeingValue}>
                {averageWellbeing() > 0 && (
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/svg/face_${constants.getFaceNumberByValue(
                      averageWellbeing()
                    )}.svg`}
                    alt={'face'}
                  />
                )}

                {averageWellbeing() > 0 ? `${averageWellbeing()}%` : '-'}
              </div>
            </div>
          </div>

          <div className={classes.dates}>
            <IconButton onClick={prevDays}>
              <svg
                width="11"
                height="17"
                viewBox="0 0 11 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.4165 15.9639L1.00005 8.48195L9.41651 1.00003"
                  stroke="#B0A1CB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
            <div className={classes.datesValue}>
              {`${format(subDays(baseDate, 6), 'MM月dd日')}〜${format(
                baseDate,
                'MM月dd日'
              )}`}
            </div>

            <IconButton
              onClick={() => {
                nextDays()
              }}
              disabled={
                differenceInDays(new Date(), baseDate) > 0 ? false : true
              }
            >
              <svg
                width="12"
                height="18"
                viewBox="0 0 12 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.89026 1.16309L10.3067 8.645L1.89026 16.1269"
                  stroke="#B0A1CB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>

          <div className={classes.graphContainer}>
            <Graph data={data} average={averageWellbeing()} />
          </div>
        </div>
      </UsefulContainer>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      //
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 20,
    },

    title: {
      fontSize: 14,

      '& span': {
        display: 'inline-block',
      },
    },

    wellBeing: {},

    wellBeingLabel: {
      textAlign: 'right',
      fontSize: 12,
      marginBottom: 8,
      '& span': {
        display: 'inline-block',
      },
    },
    wellBeingValue: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 24,

      '& img': {
        marginRight: 5,
      },
    },

    dates: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 25px',
      alignItems: 'center',
    },
    datesValue: {
      fontSize: 18,
    },

    graphContainer: {
      padding: '20px 0',
    },
  })

export default withStyles(useStyles)(connector(Index))
