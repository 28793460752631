import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import { Button } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Setting from '@/pages/settings/home/index.component'

interface IIOwnProps {
  //
}

type Props = IIOwnProps & WithStyles<typeof useStyles> & RouteComponentProps

const Index: React.FC<Props> = (props) => {
  const { classes } = props
  const [active, setActive] = React.useState()

  React.useEffect(() => {
    switch (window.location.pathname) {
      case pages.HOME:
        setActive(constants.TAB_HOME)
        break
      case pages.EMOTION_HOME:
        setActive(constants.TAB_EMOTION)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePageHome = () => {
    setActive(constants.TAB_HOME)
    props.history.push(pages.HOME)
    window.scrollTo(0, 0)
  }

  const handlePageEmotion = () => {
    setActive(constants.TAB_EMOTION)
    props.history.push(pages.EMOTION_HOME)
    window.scrollTo(0, 0)
  }

  // setting
  const [openSetting, setOpenSetting] = React.useState(false)
  const handlePageSetting = () => {
    setOpenSetting(true)
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Button
          className={`${classes.button} ${
            active === constants.TAB_HOME ? 'active' : ''
          }`}
          onClick={handlePageHome}
        >
          <div className={classes.icons}>
            <svg
              width="17"
              height="21"
              viewBox="0 0 17 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.31955 0.26721C7.72234 -0.106807 8.35149 -0.0856235 8.7282 0.314639L16.7282 8.81464C16.9028 9.00014 17 9.24527 17 9.5V19.5C17 20.0523 16.5523 20.5 16 20.5H1C0.44772 20.5 4.27848e-06 20.0523 4.27848e-06 19.5V12.5C4.27848e-06 11.9477 0.44772 11.5 1 11.5C1.55229 11.5 2 11.9477 2 12.5V18.5H15V9.89658L7.95257 2.40869L1.68046 8.2328C1.27574 8.6086 0.643014 8.58517 0.267211 8.18045C-0.108592 7.77574 -0.0851575 7.14301 0.319553 6.76721L7.31955 0.26721Z"
                fill="#333333"
              />
            </svg>
            <div>ホーム</div>
          </div>
        </Button>
        <Button
          className={`${classes.button} center ${
            active === constants.TAB_EMOTION ? 'active' : ''
          }`}
          onClick={handlePageEmotion}
        >
          <div className={classes.icons}>
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.7093 2.29514C25.0986 2.6869 25.0966 3.32006 24.7048 3.70934L15.9491 12.4098L10.4768 6.97198L6.20711 11.2417C5.81659 11.6322 5.18342 11.6322 4.7929 11.2417C4.40237 10.8512 4.40237 10.218 4.79289 9.82747L10.4723 4.14802L15.9491 9.59024L23.2951 2.29066C23.6869 1.90137 24.32 1.90338 24.7093 2.29514Z"
                fill="white"
              />
              <path
                d="M18 11C18 12.1046 17.1046 13 16 13C14.8954 13 14 12.1046 14 11C14 9.89543 14.8954 9 16 9C17.1046 9 18 9.89543 18 11Z"
                fill="white"
              />
              <path
                d="M4 14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14C0 12.8954 0.895431 12 2 12C3.10457 12 4 12.8954 4 14Z"
                fill="white"
              />
              <path
                d="M27 2C27 3.10457 26.1046 4 25 4C23.8954 4 23 3.10457 23 2C23 0.895431 23.8954 0 25 0C26.1046 0 27 0.895431 27 2Z"
                fill="white"
              />
            </svg>

            <div>感情ログ</div>
          </div>
        </Button>
        <Button
          className={`${classes.button} right ${
            active === constants.TAB_SETTING ? 'active' : ''
          }`}
          onClick={handlePageSetting}
        >
          <div className={classes.icons}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15ZM10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 4C9.44772 4 9 3.55228 9 3L9 1C9 0.447715 9.44772 -1.95703e-08 10 -4.37114e-08C10.5523 -6.78525e-08 11 0.447715 11 1L11 3C11 3.55228 10.5523 4 10 4Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C9.44772 20 9 19.5523 9 19L9 17C9 16.4477 9.44772 16 10 16C10.5523 16 11 16.4477 11 17L11 19C11 19.5523 10.5523 20 10 20Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 10C4 10.5523 3.55228 11 3 11L1 11C0.447715 11 -3.91405e-08 10.5523 -8.74228e-08 10C-1.35705e-07 9.44772 0.447715 9 1 9L3 9C3.55228 9 4 9.44772 4 10Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10C20 10.5523 19.5523 11 19 11L17 11C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9L19 9C19.5523 9 20 9.44772 20 10Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.22182 5.63615C5.83129 6.02668 5.19813 6.02668 4.8076 5.63615L2.22182 3.05037C1.83129 2.65984 1.83129 2.02668 2.22182 1.63616C2.61234 1.24563 3.24551 1.24563 3.63603 1.63616L6.22182 4.22194C6.61234 4.61247 6.61234 5.24563 6.22182 5.63615Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5355 17.9499C18.145 18.3404 17.5118 18.3404 17.1213 17.9499L14.5355 15.3641C14.145 14.9736 14.145 14.3404 14.5355 13.9499C14.9261 13.5594 15.5592 13.5594 15.9498 13.9499L18.5355 16.5357C18.9261 16.9262 18.9261 17.5594 18.5355 17.9499Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.4644 13.9499C6.85493 14.3404 6.85493 14.9736 6.4644 15.3641L3.87862 17.9499C3.48809 18.3404 2.85493 18.3404 2.4644 17.9499C2.07388 17.5594 2.07388 16.9262 2.4644 16.5357L5.05019 13.9499C5.44071 13.5594 6.07388 13.5594 6.4644 13.9499Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9497 1.46452C19.3402 1.85505 19.3402 2.48821 18.9497 2.87874L17.3639 4.46452C16.9734 4.85505 16.3402 4.85505 15.9497 4.46452C15.5592 4.074 15.5592 3.44083 15.9497 3.05031L17.5355 1.46452C17.926 1.074 18.5592 1.074 18.9497 1.46452Z"
                fill="#333333"
              />
              <path
                d="M12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
                fill="#333333"
              />
            </svg>

            <div>設定</div>
          </div>
        </Button>
      </div>

      <Setting openSetting={openSetting} setOpenSetting={setOpenSetting} />
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      height: constants.FOOTER_HEIGHT,
      position: 'absolute',
      bottom: 0,
      width: '100%',
      border: '1px solid #E1DEEB',
      background: constants.BG_LIGHT_LIGHT,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#333',
    },

    button: {
      width: '33%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 'initial',
      borderRadius: 0,
      '& path': {
        fill: '#333',
      },
      '&.active': {
        background: constants.COLOR_MAIN,
        color: '#fff',

        '& path': {
          fill: '#fff',
        },
      },

      '&.center': {
        width: '34%',
      },

      '&.right': {
        borderLeft: '1px solid #E1DEEB',
      },
    },

    icons: {
      textAlign: 'center',
      fontSize: 12,
    },
  })

export default withRouter(withStyles(useStyles)(Index))
