import { combineReducers } from 'redux'
import { Reducer as HomeReducer } from './home/reducer'
// import { IForecastList } from './interface'

export interface IState {
  // emotionsHome: IForecastList
}

export default combineReducers<IState>({
  emotionsHome: HomeReducer,
})
