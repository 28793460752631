import React from 'react'
import { useForm } from 'react-hook-form'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import {
  Button,
  createStyles,
  Theme,
  makeStyles,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/VisibilityOutlined'
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined'
import Checkbox from '@/components/Checkbox'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ModalTerm from '@/pages/components/ModalTerm'
import ModalPolicy from '@/pages/components/ModalPolicy'
import { connector, ContainerProps } from './index.container'
import { ISignup } from '@/stores/user/interface'

interface State {
  agree: boolean
  showPassword: boolean
}

type Props = ContainerProps & RouteComponentProps

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { handleSubmit, errors, register, getValues } = useForm<ISignup>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: ISignup) => {
    props.signup(data)
  }

  React.useEffect(() => {
    if (props.user.signup && props.user.email) {
      props.history.push(
        `${pages.SIGNUP_CONFIRM}?email=${encodeURIComponent(props.user.email)}`
      )
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.signup])

  const [agree, setAgree] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)

  const handleCheckAgree = () => {
    setAgree(!agree)
  }
  const handlePageSignin = () => {
    window.location.href = pages.SIGNIN
  }

  const [openModalTerm, setOpenModalTerm] = React.useState(false)
  const [openModalPolicy, setOpenModalPolicy] = React.useState(false)

  const submitable = () => {
    if (
      !getValues('username') ||
      !getValues('email') ||
      !getValues('password') ||
      errors.username ||
      errors.email ||
      errors.password ||
      !agree
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className="title">BeDo+ アカウント作成</div>
        </div>
        <div className={classes.body}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`${classes.formContainer} formContainer`}
          >
            <div className="formContainer__formControl">
              <label htmlFor="username-input">ユーザー名</label>
              <OutlinedInput
                id="username-input"
                name="username"
                placeholder="bedo"
                inputRef={register({
                  required: 'ユーザー名を入力してください',
                })}
              />
              <div className={`formContainer__errorMessage`}>
                {errors.username && errors.username.message}
              </div>
            </div>
            <div className="formContainer__formControl">
              <label htmlFor="email-input">メールアドレス</label>
              <OutlinedInput
                id="email-input"
                name="email"
                type="email"
                placeholder="abc@example.com"
                inputRef={register({
                  required: 'メールアドレスを入力してください',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: '無効なメールアドレスです',
                  },
                })}
              />
              <div className={`formContainer__errorMessage`}>
                {errors.email && errors.email.message}
              </div>
            </div>
            <div className="formContainer__formControl">
              <label htmlFor="password-input">パスワード</label>
              <OutlinedInput
                name="password"
                type={showPassword ? 'text' : 'password'}
                inputRef={register({
                  required: '現在のパスワードが入力されていません',
                  pattern: {
                    value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                    message: '半角英数字8〜24文字で入力してください',
                  },
                })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      color="secondary"
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="半角英数字8文字以上"
              />

              <div className="formContainer__errorMessage">
                {errors.password && errors.password.message}
              </div>
            </div>

            <div className={classes.checkContainer}>
              <div>
                <Checkbox onChange={handleCheckAgree} />
                <span>
                  <span
                    onClick={() => {
                      setOpenModalTerm(true)
                    }}
                    style={{ textDecoration: 'underline' }}
                  >
                    利用規約
                  </span>
                  と
                  <span
                    onClick={() => {
                      setOpenModalPolicy(true)
                    }}
                    style={{ textDecoration: 'underline' }}
                  >
                    プライバシーポリシー
                  </span>
                  に同意
                </span>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                className={`${submitable() ? 'disabled' : ''}`}
                disabled={submitable() ? true : false}
              >
                今すぐ始める
              </Button>
              {props.user.error && (
                <div className={classes.errorMessage}>
                  {props.user.error.message || props.user.error}
                </div>
              )}
            </div>
          </form>
          <div className={`${classes.Link} center`}>
            <span onClick={handlePageSignin}>ログイン</span>
          </div>
        </div>
      </div>

      <ModalTerm open={openModalTerm} setOpen={setOpenModalTerm} />
      <ModalPolicy open={openModalPolicy} setOpen={setOpenModalPolicy} />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
    header: {
      padding: '40px 38px 33px',
      textAlign: 'center',
      '&  > .title': {
        fontSize: '13px',
      },
      borderBottom: '1px solid #E1DEEB',
    },

    body: {
      padding: 20,
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',
    },

    formContainer: {
      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            background: constants.BG_LIGHT_DARK,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },

    Link: {
      fontSize: 12,
      marginBottom: 20,
      color: constants.TEXT_COLOR,
      textDecoration: 'underline',

      '&.center': {
        textAlign: 'center',
      },
    },

    checkContainer: {
      marginBottom: 20,
      fontSize: 12,
    },

    buttonContainer: {
      marginBottom: 20,
      '& button': {
        background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
        borderRadius: '30px',
        color: '#fff',
        fontSize: '16px',
        padding: '8px 0px',
        width: '100%',

        '&.disabled': {
          background: '#E1D5EC',
        },
      },
    },
    errorMessage: {
      fontSize: 12,
      color: 'red',
      textAlign: 'center',
    },
  })
)

export default withRouter(connector(Index))
