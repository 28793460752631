import React, { useCallback, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Button,
  createStyles,
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  OutlinedInput,
} from '@material-ui/core'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import { connector, ContainerProps } from './confirm.container'
import { IVerifyEmail } from '@/stores/user/interface'

type Props = ContainerProps & RouteComponentProps

const Index: React.FC<Props> = (props) => {
  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const code = params.get('code')

    if (code) {
      setValue('code', code)
      props.verifyEmail(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  // const handleResend = () => {
  //   props.resendSignUp(getValues('email'))
  // }
  React.useEffect(() => {
    if (props.user.resend) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.resend])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const { handleSubmit, errors, setValue, register, getValues } = useForm<
    IVerifyEmail
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: IVerifyEmail) => {
    props.verifyEmail(data.code)
  }

  React.useEffect(() => {
    if (!props.user.id) {
      window.location.href = pages.SIGNIN
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  const submitable = () => {
    if (!getValues('code') || errors.code) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className="title">認証コード</div>
      </div>
      <div className={classes.body}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} formContainer`}
        >
          <div className="formContainer__formControl">
            <label htmlFor="code-input">認証コード</label>
            <OutlinedInput
              id="code-input"
              name="code"
              placeholder="6桁の数字を入力"
              inputRef={register({
                required: '認証コードを入力してください',
                // TODO validation
              })}
              inputProps={{ maxLength: 6 }}
            />
            <div className={`formContainer__errorMessage`}>
              {errors.code && errors.code.message}
            </div>
          </div>

          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              className={`${submitable() ? 'disabled' : ''}`}
              disabled={submitable() ? true : false}
            >
              送信
            </Button>
            {props.user.error && (
              <div className={classes.errorMessage}>
                {props.user.error.message || props.user.error}
              </div>
            )}
          </div>
        </form>
        {/* <div className={`${classes.Link} center`}>
          <span onClick={handleResend}>もう一度確認コードを送信する</span>
        </div> */}
      </div>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogContent>確認コードを送信しました</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
    header: {
      padding: '40px 38px 33px',
      textAlign: 'center',
      '&  > .title': {
        fontSize: '13px',
      },
      borderBottom: '1px solid #E1DEEB',
    },
    body: {
      padding: 20,
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',
    },

    formContainer: {
      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            background: constants.BG_LIGHT_DARK,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },

    Link: {
      fontSize: 12,
      marginBottom: 20,
      color: constants.TEXT_COLOR,
      textDecoration: 'underline',

      '&.center': {
        textAlign: 'center',
      },
    },

    buttonContainer: {
      marginBottom: 20,
      '& button': {
        background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
        borderRadius: '30px',
        color: '#fff',
        fontSize: '16px',
        padding: '8px 0px',
        width: '100%',

        '&.disabled': {
          background: '#E1D5EC',
        },
      },
    },
    dialog: {
      padding: 20,
      fontSize: 14,
    },
    errorMessage: {
      fontSize: 12,
      color: 'red',
      textAlign: 'center',
    },
  })
)

export default withRouter(connector(Index))
