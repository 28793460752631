export const COLOR_MAIN = '#B073E5'
export const COLOR_MAIN_LIGHT = '#B0A1CB'

// export const COLOR_MAIN_DARK = '#2BB3BE'
export const COLOR_SECOND = '#F4F2F9'
// export const COLOR_SECOND_LIGHT = '#fff9b2'
// export const COLOR_BLUE = '#2CB6FF'
// export const COLOR_BLUE_LIGHT = '#80D6DD'
// export const COLOR_BLUE_EXTRA_LIGHT = '#bfeaee'
// export const COLOR_BLACK = '#292626'
// export const COLOR_WHITE = '#FFFFFF'
// export const COLOR_GRAY = '#F5F5F5'
// export const COLOR_RED = '#F54040'
// export const COLOR_ACTION_CLOSE = '#FFC3C3'
// export const COLOR_ACTION_CLOSE_DARK = '#F20E00'
// export const COLOR_ACTION_FAILED = '#DDD1FF'
// export const COLOR_ACTION_FAILED_DARK = '#704291'
// export const TEXT_GRAY = '#868484'

export const WEATHER_SUN_COLOR = '#FCB887'
export const WEATHER_CLOUD_COLOR = '#8CDEF8'
export const WEATHER_THUNDER_COLOR = '#F4D573'
export const WEATHER_TYPHOON_COLOR = '#AAC6BF'
export const WEATHER_RAIN_COLOR = '#7FA9FC'
export const WEATHER_SNOW_COLOR = '#CBC2D4'
export const WEATHER_SUN = 'SUNNY'
export const WEATHER_SUN_LABEL = '晴れ'
export const WEATHER_CLOUD = 'CLOUD'
export const WEATHER_CLOUD_LABEL = '曇り'
export const WEATHER_THUNDER = 'THUNDER'
export const WEATHER_THUNDER_LABEL = '雷'
export const WEATHER_TYPHOON = 'TYPHOON'
export const WEATHER_TYPHOON_LABEL = '台風'
export const WEATHER_RAIN = 'RAIN'
export const WEATHER_RAIN_LABEL = '雨'
export const WEATHER_SNOW = 'SNOW'
export const WEATHER_SNOW_LABEL = '雪'

export const getWeatherLabelByValue = (value: string) => {
  switch (value.toUpperCase()) {
    case WEATHER_SUN:
      return WEATHER_SUN_LABEL
    case WEATHER_CLOUD:
      return WEATHER_CLOUD_LABEL
    case WEATHER_THUNDER:
      return WEATHER_THUNDER_LABEL
    case WEATHER_TYPHOON:
      return WEATHER_TYPHOON_LABEL
    case WEATHER_RAIN:
      return WEATHER_RAIN_LABEL
    case WEATHER_SNOW:
      return WEATHER_SNOW_LABEL
    default:
      return ''
  }
}

export const getWeatherColorByValue = (value: string) => {
  switch (value.toUpperCase()) {
    case WEATHER_SUN:
      return WEATHER_SUN_COLOR
    case WEATHER_CLOUD:
      return WEATHER_CLOUD_COLOR
    case WEATHER_THUNDER:
      return WEATHER_THUNDER_COLOR
    case WEATHER_TYPHOON:
      return WEATHER_TYPHOON_COLOR
    case WEATHER_RAIN:
      return WEATHER_RAIN_COLOR
    case WEATHER_SNOW:
      return WEATHER_SNOW_COLOR
    default:
      return ''
  }
}

export const getWeatherValueByLabel = (value: string) => {
  switch (value) {
    case WEATHER_SUN_LABEL:
      return WEATHER_SUN
    case 'はれ':
      return WEATHER_SUN
    case WEATHER_CLOUD_LABEL:
      return WEATHER_CLOUD
    case 'くもり':
      return WEATHER_CLOUD
    case WEATHER_THUNDER_LABEL:
      return WEATHER_THUNDER
    case 'かみなり':
      return WEATHER_THUNDER
    case WEATHER_TYPHOON_LABEL:
      return WEATHER_TYPHOON
    case 'たいふう':
      return WEATHER_TYPHOON
    case WEATHER_RAIN_LABEL:
      return WEATHER_RAIN
    case 'あめ':
      return WEATHER_RAIN
    case WEATHER_SNOW_LABEL:
      return WEATHER_SNOW
    case 'ゆき':
      return WEATHER_SNOW
    default:
      return ''
  }
}

export const BG_LIGHT_LIGHT = '#F4F2F9'
export const BG_LIGHT_DARK = '#EEECF4'

export const TEXT_COLOR = '#333333'

export const HEADER_HEIGHT = 56
export const FOOTER_HEIGHT = 70

export const BREAKPOINT_EXTRA_LARGE = 1140
export const BREAKPOINT_LARGE = 960
export const BREAKPOINT_MEDIUM = 720
export const BREAKPOINT_SMALL = 540

export const TAB_HOME = 'TAB_HOME'
export const TAB_EMOTION = 'TAB_EMOTION'
export const TAB_SETTING = 'TAB_SETTING'

export const getWeekday = (value: number) => {
  switch (value) {
    case 0:
      return '日'
    case 1:
      return '月'
    case 2:
      return '火'
    case 3:
      return '水'
    case 4:
      return '木'
    case 5:
      return '金'
    case 6:
      return '土'
    default:
      return ''
  }
}

export const getFaceNumberByValue = (value: number) => {
  if (value >= 86) {
    return 1
  } else if (value >= 50) {
    return 2
  } else if (value >= 25) {
    return 3
  } else {
    return 4
  }
}
