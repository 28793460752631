import { reducerWithInitialState } from 'typescript-fsa-reducers'
// import { Actions } from './action'
import { IEmotionsHome } from '../interface'

export const initialState: IEmotionsHome = {
  id: undefined,
  favorite: undefined,
  processing: false,
}

export const Reducer = reducerWithInitialState(initialState)
// // favoriteForecast
// .case(Actions.favoriteForecast.started, (state, payload) => {
//   return { ...state, error: null }
// })
// .case(Actions.favoriteForecast.done, (state, payload) => {
//   return { ...state, ...payload.result, error: null }
// })
// .case(Actions.favoriteForecast.failed, (state, error) => {
//   return { ...state, ...error }
// })

// // unfavoriteForecast
// .case(Actions.unfavoriteForecast.started, (state, payload) => {
//   return { ...state, error: null }
// })
// .case(Actions.unfavoriteForecast.done, (state, payload) => {
//   return { ...state, ...payload.result, error: null }
// })
// .case(Actions.unfavoriteForecast.failed, (state, error) => {
//   return { ...state, ...error }
// })
