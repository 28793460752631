import { Auth } from 'aws-amplify'
import localize from './localize'

const signinWithLine = async () => {
  try {
    await Auth.federatedSignIn({
      customProvider: process.env.REACT_APP_COGNITO_CUSTOM_ID_PROVIDER!,
    })
    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default signinWithLine
