import { combineReducers } from 'redux'
import EmotionsReducer from './emotions/reducers'
import { IState as IEmotionsState } from './emotions/reducers'

export interface State {
  emotions: IEmotionsState
}

export default combineReducers<State>({
  emotions: EmotionsReducer,
})
