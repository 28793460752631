import React from 'react'
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
  CssBaseline,
} from '@material-ui/core'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoutes from '@/components/PrivateRoute.component'
import * as pages from '@/assets/pages'
import CommonIndex from '@/pages/components/CommonIndex'
import Signin from '@/pages/signin/index.component'
import Signup from '@/pages/signup/index.component'
import SignupConfirm from '@/pages/signup/confirm.component'
import PasswordForgot from '@/pages/signin/passwordForgot.component'
import ResetPassowrd from '@/pages/signin/resetPassword.component'

const App: React.FC = () => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <BrowserRouter>
          <Switch>
            <Route exact path={pages.SIGNIN} component={Signin} />
            <Route exact path={pages.SIGNUP} component={Signup} />
            <Route
              exact
              path={pages.PASSWORD_FORGOT}
              component={PasswordForgot}
            />
            <Route
              exact
              path={pages.RESET_PASSWORD}
              component={ResetPassowrd}
            />
            <Route exact path="/signup/confirm" component={SignupConfirm} />

            <PrivateRoutes>
              <Route path="/" component={CommonIndex} />
            </PrivateRoutes>
          </Switch>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  )
}

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontFamily: '"Hiragino Kaku Gothic Pro"',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Hiragino Kaku Gothic Pro"',
  },
  palette: {
    type: 'light',
    primary: {
      main: '#F4F2F9',
      light: '#F4F2F9',
      dark: '#443751',
    },
    secondary: {
      main: '#B0A1CB',
      light: '#B0A1CB',
    },
    error: {
      main: '#FF5454',
      light: '#FF5454',
    },
    background: {
      default: '#F4F2F9',
    },
    text: { primary: '#333333' },
  },
})

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
})

export default App
