import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'
import { RootState } from '../reducers'
import {
  signup,
  signin,
  signinWithLine,
  resetPassword,
  restore,
  confirmSignUp,
  resendSignUp,
  signout,
  currentSession,
  updateProfile,
  verifyEmail,
  changePassword,
  deleteUser,
} from './services/amplify'
import { Actions } from './action'

type Action = ActionType<typeof Actions>

const fetchEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.fetch.started.match),
    exhaustMap(({ payload }) => {
      return from(currentSession()).pipe(
        map((res) => {
          return Actions.fetch.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.fetch.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const singupEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.signup.started.match),
    exhaustMap(({ payload }) => {
      return from(signup(payload.user)).pipe(
        map((res) => {
          return Actions.signup.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.signup.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const singinEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.signin.started.match),
    exhaustMap(({ payload }) => {
      return from(signin(payload.user)).pipe(
        map((res) => {
          return Actions.signin.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.signin.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const singinWithLineEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.signinWithLine.started.match),
    exhaustMap(({ payload }) => {
      return from(signinWithLine()).pipe(
        map((res) => {
          return Actions.signinWithLine.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.signinWithLine.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const resetPasswordEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.resetPassword.started.match),
    exhaustMap(({ payload }) => {
      return from(resetPassword(payload.user)).pipe(
        map((res) => {
          return Actions.resetPassword.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.resetPassword.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const restoreEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.restore.started.match),
    exhaustMap(({ payload }) => {
      return from(restore(payload.email)).pipe(
        map((res) => {
          return Actions.restore.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.restore.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const confirmSignUpEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.confirmSignUp.started.match),
    exhaustMap(({ payload }) => {
      return from(confirmSignUp(payload.user)).pipe(
        map((res) => {
          return Actions.confirmSignUp.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.confirmSignUp.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const resendSignUpEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.resendSignUp.started.match),
    exhaustMap(({ payload }) => {
      return from(resendSignUp(payload.email)).pipe(
        map((res) => {
          return Actions.resendSignUp.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.resendSignUp.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const signoutEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.signout.started.match),
    exhaustMap(({ payload }) => {
      return from(signout()).pipe(
        map((res) => {
          return Actions.signout.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.signout.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const updateProfileEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.updateProfile.started.match),
    exhaustMap(({ payload }) => {
      return from(updateProfile({ ...payload })).pipe(
        map((res) => {
          return Actions.updateProfile.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.updateProfile.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const verifyEmailEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.verifyEmail.started.match),
    exhaustMap(({ payload }) => {
      return from(verifyEmail({ ...payload })).pipe(
        map((res) => {
          return Actions.verifyEmail.done({
            params: { ...payload },
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.verifyEmail.failed({
              params: { ...payload },
              error,
            })
          )
        )
      )
    })
  )

const changePasswordEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.changePassword.started.match),
    exhaustMap(({ payload }) => {
      return from(changePassword({ ...payload })).pipe(
        map((res) => {
          return Actions.changePassword.done({
            params: { ...payload },
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.changePassword.failed({
              params: { ...payload },
              error,
            })
          )
        )
      )
    })
  )

const deleteUserEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.deleteUser.started.match),
    exhaustMap(({ payload }) => {
      return from(deleteUser(payload.password)).pipe(
        map((res) => {
          return Actions.deleteUser.done({
            params: { ...payload },
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.deleteUser.failed({
              params: { ...payload },
              error,
            })
          )
        )
      )
    })
  )

export default [
  fetchEpic,
  singupEpic,
  singinEpic,
  singinWithLineEpic,
  resetPasswordEpic,
  restoreEpic,
  confirmSignUpEpic,
  resendSignUpEpic,
  signoutEpic,
  updateProfileEpic,
  verifyEmailEpic,
  changePasswordEpic,
  deleteUserEpic,
]
