import { combineReducers } from 'redux'
import { Reducer as UserReducer } from './user/reducer'
import { IState as IUser } from './user/interface'
import { Reducer as EmotionReducer } from './emotion/reducer'
import { IState as IEmotion } from './emotion/interface'

import PagesReducer, { State as PagesState } from '../pages/reducers'

export type RootState = {
  user: IUser
  emotion: IEmotion
  pages: PagesState
}

const reducers = combineReducers({
  user: UserReducer,
  emotion: EmotionReducer,
  pages: PagesReducer,
})

export default reducers
