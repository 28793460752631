import { Auth } from 'aws-amplify'
import { IResetPassword } from '../../interface'
import localize from './localize'

const resetPassword = async (user: IResetPassword) => {
  try {
    await Auth.forgotPasswordSubmit(user.email, user.code, user.password)
    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default resetPassword
