import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { RootStateType } from '@/stores'
import { Actions as UserActions } from '@/stores/user/action'
import { IConfirmSignUp } from '@/stores/user/interface'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    confirmSignUp: (user: IConfirmSignUp) =>
      dispatch(UserActions.confirmSignUp.started({ user })),
    resendSignUp: (email: string) =>
      dispatch(UserActions.resendSignUp.started({ email })),
    refreshErrors: () => dispatch(UserActions.refreshErrors({})),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
