import { Auth } from 'aws-amplify'
import localize from './localize'

const signout = async () => {
  try {
    await Auth.signOut()
    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default signout
