import { Auth } from 'aws-amplify'
import { IChangePassword } from '../../interface'
import localize from './localize'

const changePassword = async (data: IChangePassword) => {
  try {
    const cognitoUser = await Auth.currentUserPoolUser()
    await Auth.changePassword(cognitoUser, data.oldPassword, data.newPassword)

    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default changePassword
