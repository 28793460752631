import * as React from 'react'
import * as pages from '@/assets/pages'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connector, ContainerProps } from './PrivateRoute.container'

type Props = ContainerProps & RouteComponentProps

const Index: React.FC<Props> = ({ history, children, user, fetchUser }) => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (user.fetched) {
      if (user.id) {
        if (!user.emailVerified) {
          history.push(pages.VERIFY_EMAIL)
        }
        setLoading(false)
      } else {
        window.location.href = pages.SIGNIN
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return <>{!loading && children}</>
}

export default withRouter(connector(Index))
