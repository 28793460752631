import { Auth } from 'aws-amplify'
import localize from './localize'

const restore = async (email: string) => {
  try {
    await Auth.forgotPassword(email)
    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default restore
