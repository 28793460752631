import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { RootStateType } from '@/stores'
import { Actions as EmotionActions } from '@/stores/user/action'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUser: () => dispatch(EmotionActions.fetch.started({})),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
