import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
// import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import Footer from '@/pages/components/Footer'
import HomeIndex from '@/pages/homes/home/index.component'
import EmotionHomeIndex from '@/pages/emotions/home/index.component'
import VerifyEmail from '@/pages/settings/home/confirm.component'

interface IProps {
  children: React.ReactNode
}

type Props = IProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  return (
    <React.Fragment>
      <div className={classes.contents}>
        <Switch>
          <Route exact path="/" component={HomeIndex} />
          <Route exact path="/emotions" component={EmotionHomeIndex} />
          <Route exact path={pages.VERIFY_EMAIL} component={VerifyEmail} />
        </Switch>
      </div>

      <Footer />
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    contents: {
      height: 'calc(100vh - 70px)',
      overflow: 'scroll',
    },
  })

export default withStyles(useStyles)(Index)
