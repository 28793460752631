export const MutationFavoriteForecast = `mutation FavoriteForecast($id: ID!) {
  favoriteForecast(id: $id) {
    id
  }
}`

export const MutationUnfavoriteForecast = `mutation UnfavoriteForecast($id: ID!) {
  unfavoriteForecast(id: $id) {
    id
  }
}`
