import React from 'react'
import { useForm } from 'react-hook-form'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import {
  Button,
  createStyles,
  Theme,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connector, ContainerProps } from './passwordForgot.container'
import { IForgotPassword } from '@/stores/user/interface'

type Props = ContainerProps & RouteComponentProps

const Index: React.FC<Props> = (props) => {
  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const email = params.get('email')

    if (email) {
      setValue('email', email)
      trigger('email')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  const {
    handleSubmit,
    errors,
    register,
    getValues,
    setValue,
    trigger,
  } = useForm<IForgotPassword>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: IForgotPassword) => {
    props.restore(data.email)
  }

  React.useEffect(() => {
    if (props.user?.restored && props.user?.email) {
      props.history.push(
        `${pages.RESET_PASSWORD}?email=${encodeURIComponent(props.user.email)}`
      )
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.restored])

  const submitable = () => {
    if (!getValues('email') || errors.email) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className="title">パスワード再発行</div>
      </div>
      <div className={classes.body}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} formContainer`}
        >
          <div className="formContainer__formControl">
            <label htmlFor="email-input">メールアドレス</label>
            <OutlinedInput
              id="email-input"
              name="email"
              type="email"
              placeholder="abc@example.com"
              inputRef={register({
                required: 'メールアドレスを入力してください',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: '無効なメールアドレスです',
                },
              })}
            />
            <div className={`formContainer__errorMessage`}>
              {errors.email && errors.email.message}
            </div>
          </div>

          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              className={`${submitable() ? 'disabled' : ''}`}
              disabled={submitable() ? true : false}
            >
              送信
            </Button>
          </div>

          {props.user.error && (
            <div className={classes.errorMessage}>
              {props.user.error.message || props.user.error}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
    header: {
      padding: '40px 38px 33px',
      textAlign: 'center',
      '&  > .title': {
        fontSize: '13px',
      },
      borderBottom: '1px solid #E1DEEB',
    },
    body: {
      padding: 20,
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',
    },

    formContainer: {
      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            background: constants.BG_LIGHT_DARK,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },

    Link: {
      fontSize: 12,
      marginBottom: 20,
      color: constants.TEXT_COLOR,
      textDecoration: 'underline',

      '&.center': {
        textAlign: 'center',
      },
    },

    buttonContainer: {
      marginBottom: 20,
      '& button': {
        background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
        borderRadius: '30px',
        color: '#fff',
        fontSize: '16px',
        padding: '8px 0px',
        width: '100%',

        '&.disabled': {
          background: '#E1D5EC',
        },
      },
    },

    errorMessage: {
      fontSize: 12,
      color: 'red',
      textAlign: 'center',
    },
  })
)

export default withRouter(connector(Index))
