import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Drawer } from '@material-ui/core'
import ModalEditProfile from './components/ModalEditProfile.component'
import ModalEditPassword from './components/ModalEditPassword.component'
import ModalTerm from '@/pages/components/ModalTerm'
import ModalPolicy from '@/pages/components/ModalPolicy'
import { connector, ContainerProps } from './index.container'

interface IOwnProps {
  openSetting: boolean
  setOpenSetting: React.Dispatch<React.SetStateAction<boolean>>
}

type Props = IOwnProps &
  ContainerProps &
  RouteComponentProps &
  WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  const [openModalEditProfile, setOpenModalEditProfile] = React.useState(false)
  const [openModalEditPassword, setOpenModalEditPassword] = React.useState(
    false
  )
  const [openModalTerm, setOpenModalTerm] = React.useState(false)
  const [openModalPolicy, setOpenModalPolicy] = React.useState(false)

  const handleSignout = () => {
    props.signout()
  }

  React.useEffect(() => {
    if (!props.user.id) {
      window.location.href = pages.SIGNIN
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  const [showAlert, setShowAlert] = React.useState('')
  React.useEffect(() => {
    if (props.user.processed) {
      setShowAlert('更新しました')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.processed])

  React.useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert('')
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert])

  return (
    <React.Fragment>
      <Drawer
        anchor={'bottom'}
        open={props.openSetting}
        onClose={() => {
          props.setOpenSetting(false)
        }}
      >
        <div style={{ height: '100vh' }} className={classes.root}>
          <ul>
            <li
              onClick={() => {
                props.setOpenSetting(false)
              }}
            >
              <svg
                width="18"
                height="11"
                viewBox="0 0 18 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6902 1.27295L9.20827 9.68941L1.72635 1.27295"
                  stroke="#B0A1CB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </li>
            <li
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onClick={() => {
                setOpenModalEditProfile(true)
              }}
            >
              <div>アカウント設定</div>
              <div>
                <svg
                  width="9"
                  height="13"
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.89014 1L7.89014 6.33378L1.89014 11.6676"
                    stroke="#B0A1CB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </li>
            <li
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onClick={() => {
                setOpenModalEditPassword(true)
              }}
            >
              <div>パスワードの変更</div>
              <div>
                <svg
                  width="9"
                  height="13"
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.89014 1L7.89014 6.33378L1.89014 11.6676"
                    stroke="#B0A1CB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </li>
            <li
              onClick={() => {
                setOpenModalTerm(true)
              }}
            >
              利用規約
            </li>
            <li
              onClick={() => {
                setOpenModalPolicy(true)
              }}
            >
              プライバシーポリシー
            </li>
            <li onClick={handleSignout}>ログアウト</li>
          </ul>

          {showAlert && (
            <div className={`${classes.alertContainer} alertContainer`}>
              <div className="alertContainer__alert">{showAlert}</div>
            </div>
          )}
        </div>
      </Drawer>

      <ModalEditProfile
        open={openModalEditProfile}
        setOpen={setOpenModalEditProfile}
      />
      <ModalEditPassword
        open={openModalEditPassword}
        setOpen={setOpenModalEditPassword}
      />
      <ModalTerm open={openModalTerm} setOpen={setOpenModalTerm} />
      <ModalPolicy open={openModalPolicy} setOpen={setOpenModalPolicy} />
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      background: constants.BG_LIGHT_LIGHT,

      '& ul': {
        '& li': {
          padding: 20,
          borderBottom: `1px solid #E1DEEB`,
        },
      },
    },

    alertContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 90,
      padding: 20,

      '& .alertContainer': {
        '&__alert': {
          background: '#E1DEEB',
          borderRadius: 10,
          boxShadow: '5px 5px 15px rgba(188, 109, 236, 0.15)',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          animation: '$fade-in 1s',
        },
      },
    },
    '@keyframes fade-in': {
      from: {
        opacity: 0,
      },

      to: {
        opacity: 1,
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
