import React from 'react'
import { useForm } from 'react-hook-form'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import {
  Button,
  createStyles,
  Theme,
  makeStyles,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/VisibilityOutlined'
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connector, ContainerProps } from './index.container'
import { ISignin } from '@/stores/user/interface'

type Props = ContainerProps & RouteComponentProps

const Index: React.FC<Props> = (props) => {
  React.useEffect(() => {
    props.onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  const { handleSubmit, errors, register, getValues } = useForm<ISignin>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: ISignin) => {
    props.signin(data)
  }

  React.useEffect(() => {
    if (props.user.id) {
      const query = new URLSearchParams(props.location.search)
      const r = query.get('r')
      props.history.push(r || pages.HOME)
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  const [showPassword, setShowPassword] = React.useState(false)

  const handlePagePasswordForgot = () => {
    props.history.push(
      `${pages.PASSWORD_FORGOT}?email=${encodeURIComponent(getValues('email'))}`
    )
    window.scrollTo(0, 0)
  }
  const handlePageSignup = () => {
    props.history.push(pages.SIGNUP)
    window.scrollTo(0, 0)
  }

  const submitable = () => {
    if (
      !getValues('email') ||
      !getValues('password') ||
      errors.email ||
      errors.password
    ) {
      return true
    } else {
      return false
    }
  }

  const handleLineLogin = () => {
    props.signinWithLine()
  }

  const [confirmed, setConfirmed] = React.useState(false)
  React.useEffect(() => {
    if (props.user.confirmed) {
      setConfirmed(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.confirmed])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className="title">BeDo+ ログイン</div>
      </div>
      <div className={classes.body}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} formContainer`}
        >
          <div className="formContainer__formControl">
            <label htmlFor="email-input">メールアドレス</label>
            <OutlinedInput
              id="email-input"
              name="email"
              type="email"
              placeholder="abc@example.com"
              inputRef={register({
                required: 'メールアドレスを入力してください',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: '無効なメールアドレスです',
                },
              })}
            />
            <div className={`formContainer__errorMessage`}>
              {errors.email && errors.email.message}
            </div>
          </div>
          <div className="formContainer__formControl">
            <label htmlFor="password-input">パスワード</label>
            <OutlinedInput
              name="password"
              type={showPassword ? 'text' : 'password'}
              inputRef={register({
                required: '現在のパスワードが入力されていません',
                pattern: {
                  value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                  message: '半角英数字8〜24文字で入力してください',
                },
              })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    color="secondary"
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="半角英数字8文字以上"
            />

            <div className="formContainer__errorMessage">
              {errors.password && errors.password.message}
            </div>
          </div>

          <div className={classes.Link}>
            <span onClick={handlePagePasswordForgot}>
              パスワードをお忘れの方
            </span>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              className={`${submitable() ? 'disabled' : ''}`}
              disabled={submitable() ? true : false}
            >
              ログイン
            </Button>

            {confirmed && (
              <div className={classes.confirmed}>
                メールアドレス認証に成功しました
              </div>
            )}

            {props.user.error && (
              <div className={classes.errorMessage}>
                {props.user.error.message || props.user.error}
              </div>
            )}
          </div>
        </form>
        <div className={classes.lineButtonContainer}>
          <Button title="LINEでログインする" onClick={handleLineLogin}>
            LINEでログイン
          </Button>
        </div>
        <div className={`${classes.Link} center`}>
          <span onClick={handlePageSignup}>アカウント作成</span>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
    header: {
      padding: '40px 38px 33px',
      textAlign: 'center',
      '&  > .title': {
        fontSize: '13px',
      },
      borderBottom: '1px solid #E1DEEB',
    },
    body: {
      padding: 20,
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',
    },
    formContainer: {
      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            background: constants.BG_LIGHT_DARK,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },

    Link: {
      fontSize: 12,
      marginBottom: 20,
      color: constants.TEXT_COLOR,
      textDecoration: 'underline',

      '&.center': {
        textAlign: 'center',
      },
    },

    buttonContainer: {
      marginBottom: 20,
      '& button': {
        background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
        borderRadius: '30px',
        color: '#fff',
        fontSize: '16px',
        padding: '8px 0px',
        width: '100%',

        '&.disabled': {
          background: '#E1D5EC',
        },
      },
    },
    errorMessage: {
      fontSize: 12,
      color: 'red',
      textAlign: 'center',
    },

    confirmed: {
      fontSize: 12,
      color: constants.COLOR_MAIN,
      textAlign: 'center',
    },

    lineButtonContainer: {
      marginBottom: 20,
      '&  > button': {
        background: '#00C300',
        borderRadius: '30px',
        color: '#fff',
        fontSize: '16px',
        padding: '8px 0px',
        width: '100%',
        '&:hover': {
          background: '#00C300',
        },
      },
    },
  })
)

export default withRouter(connector(Index))
