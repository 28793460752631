import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import UsefulContainer from '@/pages/components/UsefulContainer'
import * as constants from '@/assets/constants'
import Header, { ACTIVE_BADGE_ALL } from './components/Header'
import Card from './components/Card'
import { connector, ContainerProps } from './index.container'
import { IForecast } from '@/stores/emotion/interface'

type Props = ContainerProps & WithStyles<typeof useStyles>
const Index: React.FC<Props> = (props) => {
  React.useEffect(() => {
    if (props.emotion.all.length === 0) {
      props.fetchEmotions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // header
  const [activeBadge, setActiveBadge] = React.useState(ACTIVE_BADGE_ALL)
  const [searchStr, setSearchStr] = React.useState('')
  React.useEffect(() => {
    const words = searchStr.replace('　', ' ').split(' ')
    if (activeBadge === ACTIVE_BADGE_ALL) {
      const filteredData = props.emotion.all.filter((current) => {
        let catched = false
        words.forEach((word) => {
          if (current.weather === constants.getWeatherValueByLabel(word)) {
            catched = true
          }
        })
        return catched
      })
      setSearchedData(filteredData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr])
  const [searchedData, setSearchedData] = React.useState<IForecast[]>([])

  const { classes } = props

  return (
    <React.Fragment>
      <UsefulContainer>
        <div className={classes.root}>
          <Header
            numAll={props.emotion.all.length}
            numFavorite={props.emotion.favorite.length}
            activeBadge={activeBadge}
            setActiveBadge={setActiveBadge}
            setSearchStr={setSearchStr}
          />

          <div className={classes.contents}>
            {searchStr === '' ? (
              <>
                {activeBadge === ACTIVE_BADGE_ALL ? (
                  <>
                    {props.emotion.all.length > 0 ? (
                      <>
                        {props.emotion.all.map((data, index) => (
                          <Card
                            key={`card-${data.id}`}
                            data={data}
                            actionFavorite={props.favorite}
                            actionUnfavorite={props.unfavorite}
                          />
                        ))}
                      </>
                    ) : (
                      <div className={classes.noData}>ログがありません</div>
                    )}
                  </>
                ) : (
                  <>
                    {props.emotion.favorite.length > 0 ? (
                      <>
                        {props.emotion.favorite.map((data, index) => (
                          <Card
                            key={`card-${data.id}`}
                            data={data}
                            actionFavorite={props.favorite}
                            actionUnfavorite={props.unfavorite}
                          />
                        ))}
                      </>
                    ) : (
                      <div className={classes.noData}>ログがありません</div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {searchedData.length > 0 ? (
                  <>
                    {searchedData.map((data, index) => (
                      <Card
                        key={`card-${data.id}`}
                        data={data}
                        actionFavorite={props.favorite}
                        actionUnfavorite={props.unfavorite}
                      />
                    ))}
                  </>
                ) : (
                  <div className={classes.noData}>検索結果がありません</div>
                )}
              </>
            )}
          </div>
        </div>
      </UsefulContainer>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      //
    },
    contents: {
      padding: `100px 20px ${constants.FOOTER_HEIGHT}px`,
      maxWidth: constants.BREAKPOINT_MEDIUM,
      margin: '0 auto',
    },

    noData: {
      fontSize: 12,
      height: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: constants.COLOR_MAIN_LIGHT,
    },
  })

export default withStyles(useStyles)(connector(Index))
