import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Button,
  createStyles,
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import Visibility from '@material-ui/icons/VisibilityOutlined'
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined'
import { connector, ContainerProps } from './resetPassword.container'
import { IResetPassword } from '@/stores/user/interface'

type Props = ContainerProps & RouteComponentProps

const Index: React.FC<Props> = (props) => {
  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const email = params.get('email')
    const code = params.get('code')

    if (email) {
      setValue('email', email)
    } else {
      props.history.push('/')
      window.scrollTo(0, 0)
    }
    if (code) {
      setValue('code', code)
      // TODO
      // props.confirmSignUp(getValues())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const handleResend = () => {
    props.resendRestore(getValues('email'))
  }

  React.useEffect(() => {
    if (props.user.restored) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.restored])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const { handleSubmit, errors, setValue, register, getValues } = useForm<
    IResetPassword
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: IResetPassword) => {
    props.resetPassword(data)
  }

  React.useEffect(() => {
    if (props.user?.reset) {
      window.location.href = pages.SIGNIN
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.reset])

  const submitable = () => {
    if (
      !getValues('email') ||
      !getValues('code') ||
      !getValues('password') ||
      !getValues('passwordRe') ||
      errors.email ||
      errors.code ||
      errors.password ||
      errors.passwordRe
    ) {
      return true
    } else {
      return false
    }
  }

  const [showPassword, setShowPassword] = React.useState(false)
  const [showPasswordRe, setShowPasswordRe] = React.useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className="title">認証コード</div>
      </div>
      <div className={classes.body}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} formContainer`}
        >
          <input type="hidden" name="email" ref={register({})} />

          <div className="formContainer__formControl">
            <label htmlFor="code-input">認証コード</label>
            <OutlinedInput
              id="code-input"
              name="code"
              placeholder="6桁の数字を入力"
              inputRef={register({
                required: '認証コードを入力してください',
                // TODO validation
              })}
              inputProps={{ maxLength: 6 }}
              autoComplete="none"
            />
            <div className={`formContainer__errorMessage`}>
              {errors.code && errors.code.message}
            </div>
          </div>

          <div className="formContainer__formControl">
            <label>新しいパスワード</label>
            <OutlinedInput
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="半角英数字8文字以上"
              inputRef={register({
                required: '新しいパスワードが入力されていません',
                pattern: {
                  value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                  message: '半角英数字8〜24文字で入力してください',
                },
              })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    color="secondary"
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              autoComplete="new-password"
            />

            <div className="formContainer__errorMessage">
              {errors.password && errors.password.message}
            </div>
          </div>
          <div className="formContainer__formControl">
            <label>新しいパスワード（再入力）</label>
            <OutlinedInput
              name="passwordRe"
              type={showPasswordRe ? 'text' : 'password'}
              placeholder="半角英数字8文字以上"
              inputRef={register({
                required: '新しいパスワード（再入力）が入力されていません',
                pattern: {
                  value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                  message: '半角英数字8〜24文字で入力してください',
                },
                validate: {
                  same: (value) =>
                    value === getValues('password') ||
                    'パスワードが一致しません。',
                },
              })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    color="secondary"
                    onClick={() => {
                      setShowPasswordRe(!showPasswordRe)
                    }}
                  >
                    {showPasswordRe ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <div className="formContainer__errorMessage">
              {errors.passwordRe && errors.passwordRe.message}
            </div>
          </div>

          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              className={`${submitable() ? 'disabled' : ''}`}
              disabled={submitable() ? true : false}
            >
              送信
            </Button>
          </div>

          {props.user.error && (
            <div className={classes.errorMessage}>
              {props.user.error.message || props.user.error}
            </div>
          )}
        </form>
        <div className={`${classes.Link} center`}>
          <span onClick={handleResend}>もう一度確認コードを送信する</span>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogContent>確認コードを送信しました</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
    header: {
      padding: '40px 38px 33px',
      textAlign: 'center',
      '&  > .title': {
        fontSize: '13px',
      },
      borderBottom: '1px solid #E1DEEB',
    },
    body: {
      padding: 20,
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',
    },

    formContainer: {
      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            background: constants.BG_LIGHT_DARK,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },

    Link: {
      fontSize: 12,
      marginBottom: 20,
      color: constants.TEXT_COLOR,
      textDecoration: 'underline',

      '&.center': {
        textAlign: 'center',
      },
    },

    buttonContainer: {
      marginBottom: 20,
      '& button': {
        background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
        borderRadius: '30px',
        color: '#fff',
        fontSize: '16px',
        padding: '8px 0px',
        width: '100%',

        '&.disabled': {
          background: '#E1D5EC',
        },
      },
    },
    dialog: {
      padding: 20,
      fontSize: 14,
    },
    //
    //
    //
    formMainBody: {
      width: '100%',
      padding: '15px 19px',
    },
    label: {
      fontSize: '13px',
      textAlign: 'left',
      marginBottom: 11,
    },
    code: {
      width: '100%',
    },
    reSendConfirmCode: {
      marginTop: 8,
    },
    validationMessages: {
      marginTop: 5,
      textAlign: 'left',
      color: theme.palette.error.main,
      fontSize: '12px',
    },
    sendButtonControl: {
      width: '100%',
      marginTop: 30,
    },
    sendButton: {
      background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
      borderRadius: '30px',
      color: '#fff',
      fontSize: '16px',
      padding: '8px 0px',
      margin: '15px 19px 20px',
    },
    disabledSendButton: {
      background: '#E1D5EC',
    },
    underLineLink: {
      textAlign: 'left',
      fontSize: '12px',
      textDecoration: 'underline',
      '& > a': {
        color: theme.palette.text.primary,
      },
    },
    toLogin: {
      textAlign: 'center',
    },

    errorMessage: {
      fontSize: 12,
      color: 'red',
      textAlign: 'center',
    },
  })
)

export default connector(Index)
