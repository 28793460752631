import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'
import { RootState } from '@/stores/reducers'
import { Actions } from './action'
import { getForecastList } from './api'

type Action = ActionType<typeof Actions>

const fetchEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.fetch.started.match),
    exhaustMap(({ payload }) => {
      return from(getForecastList()).pipe(
        map((res) => {
          return Actions.fetch.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            Actions.fetch.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

export default [fetchEpic]
