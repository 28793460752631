import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Actions } from './action'
import { IState } from './interface'

export const initialState: IState = {
  all: [],
  favorite: [],
}

export const Reducer = reducerWithInitialState(initialState)
  // fetch
  .case(Actions.fetch.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.fetch.done, (state, payload) => {
    return { ...state, ...payload.result, error: null }
  })
  .case(Actions.fetch.failed, (state, error) => {
    return { ...state, ...error }
  })
  // favorite
  .case(Actions.favorite.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.favorite.done, (state, payload) => {
    const add = state.all.find((current) => {
      return current.id === payload.result.id
    })
    if (add) {
      const favorite = [add, ...state.favorite]
      return { ...state, favorite, error: null }
    } else {
      return { ...state, error: null }
    }
  })
  .case(Actions.favorite.failed, (state, error) => {
    return { ...state, ...error }
  })
  // unfavorite
  .case(Actions.unfavorite.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.unfavorite.done, (state, payload) => {
    const favorite = state.favorite.filter((current) => {
      return current.id !== payload.result.id
    })

    const all = state.all.map((current) => {
      if (current.id === payload.result.id) {
        current.isFavorite = false
        return current
      } else {
        return current
      }
    })
    return { ...state, all, favorite, error: null }
  })
  .case(Actions.unfavorite.failed, (state, error) => {
    return { ...state, ...error }
  })
