import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
// import * as constants from "@/assets/constants";

interface IProps {
  children: React.ReactNode
}

type Props = IProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props
  return (
    <React.Fragment>
      <div className={classes.contents}>{props.children}</div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    contents: {
      height: 'calc(100vh - 70px)',
      overflow: 'scroll',
    },
  })

export default withStyles(useStyles)(Index)
