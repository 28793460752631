import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'
import { RootState } from '@/stores/reducers'
import { Actions } from './action'
import { Actions as EmotionActions } from '@/stores/emotion/action'
import { favoriteForecast, unfavoriteForecast } from './api'

type Action = ActionType<typeof Actions>

const favoriteForecastEpic: Epic<Action, Action, RootState> = (
  actions$,
  store
) =>
  actions$.pipe(
    filter(EmotionActions.favorite.started.match),
    exhaustMap(({ payload }) => {
      return from(favoriteForecast({ ...payload })).pipe(
        map((res) => {
          return EmotionActions.favorite.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            EmotionActions.favorite.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const unfavoriteForecastEpic: Epic<Action, Action, RootState> = (
  actions$,
  store
) =>
  actions$.pipe(
    filter(EmotionActions.unfavorite.started.match),
    exhaustMap(({ payload }) => {
      return from(unfavoriteForecast({ ...payload })).pipe(
        map((res) => {
          return EmotionActions.unfavorite.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            EmotionActions.unfavorite.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

export default [favoriteForecastEpic, unfavoriteForecastEpic]
