import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import {
  Modal,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Visibility from '@material-ui/icons/VisibilityOutlined'
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined'
import { connector, ContainerProps } from './ModalEditPassword.container'
import { IChangePassword } from '@/stores/user/interface'

interface IOwnProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type Props = IOwnProps & ContainerProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props
  const { handleSubmit, errors, register } = useForm<IChangePassword>()

  const onSubmit = (data: IChangePassword) => {
    props.changePassword(data)
  }

  React.useEffect(() => {
    if (!props.user.processing && props.user.processed) {
      props.setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.processing])

  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Modal
          open={props.open}
          className={classes.modal}
          disableBackdropClick={true}
        >
          <div className={classes.modalContainer}>
            <div
              className={`${classes.closeContainer} closeContainer`}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <div className="closeContainer__iconContainer">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 12L1 6.66622L7 1.33244"
                    stroke="#B0A1CB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="closeContainer__label">戻る</div>
            </div>
            <form
              className={`${classes.formContainer} formContainer`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="formContainer__formControl">
                <label>現在のパスワード</label>
                <OutlinedInput
                  name="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  inputRef={register({
                    required: '現在のパスワードが入力されていません',
                    pattern: {
                      value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                      message: '半角英数字8〜24文字で入力してください',
                    },
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        color="secondary"
                        onClick={() => {
                          setShowOldPassword(!showOldPassword)
                        }}
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <div className="formContainer__errorMessage">
                  {errors.oldPassword && errors.oldPassword.message}
                </div>
              </div>
              <div className="formContainer__formControl">
                <label>新しいパスワード</label>
                <OutlinedInput
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  inputRef={register({
                    required: '新しいパスワードが入力されていません',
                    pattern: {
                      value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                      message: '半角英数字8〜24文字で入力してください',
                    },
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        color="secondary"
                        onClick={() => {
                          setShowNewPassword(!showNewPassword)
                        }}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <div className="formContainer__errorMessage">
                  {errors.newPassword && errors.newPassword.message}
                </div>
              </div>

              <div className={`${classes.buttonContianer} buttonContianer`}>
                <Button className="buttonContianer__button" type="submit">
                  更新する
                </Button>
                {props.user.error && (
                  <div className={classes.errorMessage}>
                    {props.user.error.message || props.user.error}
                  </div>
                )}
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},

    modal: {},

    modalContainer: {
      background: constants.BG_LIGHT_LIGHT,
      height: '100vh',
    },

    closeContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 20,
      borderBottom: `1px solid #E1DEEB`,

      '& .closeContainer': {
        '&__iconContainer': {
          display: 'flex',
          alignItems: 'center',
          marginRight: 10,
        },

        '&__label': {},
      },
    },

    formContainer: {
      padding: 20,
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',

      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            background: constants.BG_LIGHT_DARK,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },
    buttonContianer: {
      '& .buttonContianer': {
        '&__button': {
          width: '100%',
          color: '#fff',
          height: 50,
          borderRadius: 25,

          background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
        },
      },
    },

    errorMessage: {
      fontSize: 12,
      color: 'red',
      textAlign: 'center',
    },
  })

export default withStyles(useStyles)(connector(Index))
