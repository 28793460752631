import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from '@/stores'
import App from '@/App'
import Amplify from 'aws-amplify'
import '@/index.scss'

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_URL,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
    // graphql_headers: async () => {
    //   const currentSession = await Auth.currentSession()
    //   return { Authorization: currentSession.getIdToken().getJwtToken() }
    // },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
