import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts'
import * as constants from '@/assets/constants'

interface IOwnProps {
  data: IGraphData[]
  average: number
}

export interface IGraphData {
  weekday: string
  value: number | null
  weather: string | null
}

type Props = IOwnProps & WithStyles<typeof useStyles>
const Index: React.FC<Props> = (props) => {
  const { classes } = props

  const CustomizedDot = (props: any) => {
    const { cx, cy, payload } = props
    if (payload.weather && payload.value) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill={constants.getWeatherColorByValue(payload.weather)}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.5" cy="8.5" r="8.5" />
        </svg>
      )
    } else {
      return null
    }
  }

  const CustomTooltip = (props: any) => {
    const { active, payload } = props

    if (active && payload[0]) {
      if (payload[0].payload.weather) {
        return (
          <div
            style={{
              padding: '5px 10px',
              color: '#fff',
              borderRadius: 5,
              background: constants.COLOR_MAIN,
            }}
          >
            <p className="label">
              {constants.getWeatherLabelByValue(payload[0].payload.weather)}
            </p>
          </div>
        )
      } else {
        return null
      }
    }
    return null
  }

  const CustomizedTick = (props: any) => {
    const { y, payload } = props
    if (payload.value === 100) {
      return (
        <g transform={`translate(15,${y})`}>
          <svg
            width="20"
            height="20"
            x={30}
            y={-5}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
              fill="#333333"
            />
            <path
              d="M12 9.5C12 10.3284 11.3284 11 10.5 11C9.67157 11 9 10.3284 9 9.5C9 8.67157 9.67157 8 10.5 8C11.3284 8 12 8.67157 12 9.5Z"
              fill="#333333"
            />
            <path
              d="M21 9.5C21 10.3284 20.3284 11 19.5 11C18.6716 11 18 10.3284 18 9.5C18 8.67157 18.6716 8 19.5 8C20.3284 8 21 8.67157 21 9.5Z"
              fill="#333333"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 16C7 15.4477 7.44771 15 8 15L22 15C22.5523 15 23 15.4477 23 16C23 20.4183 19.4183 24 15 24C10.5817 24 7 20.4183 7 16ZM21.9291 17C21.4439 20.3923 18.5265 23 15 23C11.4735 23 8.55612 20.3923 8.07089 17C8.02417 16.6734 8 16.3395 8 16L22 16C22 16.3395 21.9758 16.6734 21.9291 17Z"
              fill="#333333"
            />
          </svg>
          <text x={10} y={35}>
            100%
          </text>
        </g>
      )
    } else if (payload.value === 0) {
      return (
        <g transform={`translate(15,${y})`}>
          <svg
            width="20"
            height="20"
            x={30}
            y={-35}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
              fill="#333333"
            />
            <path
              d="M12 9.5C12 10.3284 11.3284 11 10.5 11C9.67157 11 9 10.3284 9 9.5C9 8.67157 9.67157 8 10.5 8C11.3284 8 12 8.67157 12 9.5Z"
              fill="#333333"
            />
            <path
              d="M21 9.5C21 10.3284 20.3284 11 19.5 11C18.6716 11 18 10.3284 18 9.5C18 8.67157 18.6716 8 19.5 8C20.3284 8 21 8.67157 21 9.5Z"
              fill="#333333"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.0001 16.5C14.1971 16.5 12.9348 16.6585 11.7071 17.1337C10.4816 17.6081 9.22955 18.4231 8.5529 19.7764C8.4294 20.0234 8.52951 20.3237 8.7765 20.4472C9.02349 20.5707 9.32383 20.4706 9.44732 20.2236C9.97067 19.1769 10.9686 18.4919 12.0681 18.0663C13.1654 17.6415 14.3031 17.5 15.0001 17.5C15.6971 17.5 16.8348 17.6415 17.9321 18.0663C19.0316 18.4919 20.0296 19.1769 20.5529 20.2236C20.6764 20.4706 20.9767 20.5707 21.2237 20.4472C21.4707 20.3237 21.5708 20.0234 21.4473 19.7764C20.7707 18.4231 19.5186 17.6081 18.2931 17.1337C17.0654 16.6585 15.8031 16.5 15.0001 16.5Z"
              fill="#333333"
            />
          </svg>

          <text x={30} y={5}>
            0%
          </text>
        </g>
      )
    } else {
      return null
    }

    // return (

    //   // <g transform={`translate(${x},${y})`}>
    //     <text
    //       x={0}
    //       y={0}
    //       dy={16}
    //       textAnchor="end"
    //       fill="#666"
    //       transform="rotate(-35)"
    //     >
    //       {payload.value}
    //     </text>
    //   // </g>
    // )
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        {props.average > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              width={500}
              height={300}
              data={props.data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="weekday" padding={{ left: 15, right: 0 }} />
              <YAxis unit="%" domain={[0, 100]} tick={<CustomizedTick />} />
              <Tooltip content={<CustomTooltip classes={classes} />} />
              <Line
                type="linear"
                dataKey="value"
                stroke="#E1DEEB"
                connectNulls
                dot={<CustomizedDot />}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className={classes.noData}>ログがありません</div>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      //
    },

    noData: {
      height: 300,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: constants.COLOR_MAIN_LIGHT,
    },
  })

export default withStyles(useStyles)(Index)
