import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import {
  Modal,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Visibility from '@material-ui/icons/VisibilityOutlined'
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined'
import { connector, ContainerProps } from './ModalAccountDelete.container'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface IInputDeleteAccount {
  password: string
}

interface IOwnProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type Props = IOwnProps &
  ContainerProps &
  RouteComponentProps &
  WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props
  React.useEffect(() => {
    getValues('password')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleSubmit, errors, register, getValues } = useForm<
    IInputDeleteAccount
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: IInputDeleteAccount) => {
    props.deleteUser(data.password)
  }

  React.useEffect(() => {
    if (!props.user.processing && props.user.processed) {
      window.location.href = pages.SIGNIN
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.processing])

  const handleBack = () => {
    props.setOpen(false)
  }

  const [showPassword, setshowPassword] = React.useState(false)

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Modal
          open={props.open}
          className={classes.modal}
          disableBackdropClick={true}
        >
          <div className={classes.modalContainer}>
            <div className={classes.closeContainer}>
              <IconButton
                onClick={() => {
                  props.setOpen(false)
                }}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="2.00014"
                    y1="12.7284"
                    x2="13.3138"
                    y2="1.41466"
                    stroke="#B073E5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <line
                    x1="13.3137"
                    y1="12.7275"
                    x2="2"
                    y2="1.41383"
                    stroke="#B073E5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
            <div className={`${classes.contentsContainer} contentsContainer`}>
              <div className="contentsContainer__title">
                アカウントを削除しますか？
              </div>
              <p className={'contentsContainer__description'}>
                データベースからすべてのデータが完全に削除されます。続行するにはパスワードを入力してください。
              </p>
              <form
                className={`${classes.formContainer} formContainer`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="formContainer__formControl">
                  <label>パスワード</label>
                  <OutlinedInput
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    inputRef={register({
                      required: 'パスワードが入力されていません',
                      pattern: {
                        value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                        message: '半角英数字8〜24文字で入力してください',
                      },
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          color="secondary"
                          onClick={() => {
                            setshowPassword(!showPassword)
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <div className="formContainer__errorMessage">
                    {errors.password && errors.password.message}
                  </div>
                </div>

                <div className={`${classes.buttonContianer} buttonContianer`}>
                  <Button
                    className="buttonContianer__button first"
                    onClick={handleBack}
                  >
                    今はしない
                  </Button>
                  <Button
                    className={`buttonContianer__button ${
                      !getValues('password') || errors.password
                        ? 'disabled'
                        : ''
                    }`}
                    type="submit"
                    disabled={
                      props.user.processing
                        ? true
                        : !getValues('password') || errors.password
                        ? true
                        : false
                    }
                  >
                    削除する
                  </Button>

                  {props.user.error && (
                    <div
                      className="formContainer__errorMessage"
                      style={{ textAlign: 'center' }}
                    >
                      パスワードが認証されませんでした
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},

    modal: {},

    modalContainer: {
      background: constants.BG_LIGHT_LIGHT,
      height: '100vh',
      padding: 20,
    },

    closeContainer: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },

    contentsContainer: {
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',
      '& .contentsContainer': {
        '&__title': {
          fontSize: 18,
          padding: '40px 0',
          textAlign: 'center',
        },
        '&__description': {
          fontSize: 13,
          padding: '0 20px',
          lineHeight: '22px',
        },
      },
    },

    formContainer: {
      paddingTop: 20,

      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            background: constants.BG_LIGHT_DARK,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },
    buttonContianer: {
      '& .buttonContianer': {
        '&__button': {
          width: '100%',
          color: '#fff',
          height: 50,
          borderRadius: 25,

          background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',

          '&.first': {
            marginBottom: 20,
          },

          '&.disabled': {
            background: '#968C9F',
            color: '#BDBDBD',
          },
        },
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
