import { actionCreatorFactory } from 'typescript-fsa'
import {
  SIGNUP_USER,
  SET_SETUP_STARTED_AT_USER,
  REFRESH_STATE_USER,
  SIGNIN_USER,
  REFRESH_ERRORS_USER,
  SET_EMAIL_TO_PARAM_USER,
  RESET_PASSWORD_USER,
  RESTORE_USER,
  CONFIRM_USER,
  RESEND_CODE_USER,
} from './types'
import {
  IState,
  ISignup,
  ISignin,
  IResetPassword,
  IConfirmSignUp,
  IInputProfile,
  IVerifyEmail,
  IChangePassword,
} from './interface'

const actionCreator = actionCreatorFactory()
export const Actions = {
  fetch: actionCreator.async<{}, IState>('FETCH_USER'),
  signup: actionCreator.async<{ user: ISignup }, {}>(SIGNUP_USER),
  confirmSignUp: actionCreator.async<{ user: IConfirmSignUp }, {}>(
    CONFIRM_USER
  ),
  resendSignUp: actionCreator.async<{ email: string }, {}>(RESEND_CODE_USER),
  refresState: actionCreator<{}>(REFRESH_STATE_USER),
  signin: actionCreator.async<{ user: ISignin }, {}>(SIGNIN_USER),
  signinWithLine: actionCreator.async<{}, {}>('SIGNIN_WITH_LINE_USER'),
  signout: actionCreator.async<{}, {}>('SIGNOUT_USER'),
  restore: actionCreator.async<{ email: string }, {}>(RESTORE_USER),
  resetPassword: actionCreator.async<{ user: IResetPassword }, {}>(
    RESET_PASSWORD_USER
  ),
  refreshErrors: actionCreator<{}>(REFRESH_ERRORS_USER),
  setEmailToParam: actionCreator<{ email: string }>(SET_EMAIL_TO_PARAM_USER),
  setSetupStartedAt: actionCreator.async<{ params: { date: Date } }, {}>(
    SET_SETUP_STARTED_AT_USER
  ),
  //
  updateProfile: actionCreator.async<IInputProfile, IState>(
    'UPDATE_PROFILE_USER'
  ),
  verifyEmail: actionCreator.async<IVerifyEmail, {}>('VERIFY_EMAIL'),
  changePassword: actionCreator.async<IChangePassword, {}>('CHANGE_PASSWORD'),
  deleteUser: actionCreator.async<{ password: string }, {}>('DELETE_USER'),
}
