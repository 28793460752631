import { Auth } from 'aws-amplify'
import localize from './localize'

const deleteUser = async (password: string) => {
  try {
    const cognitoUser = await Auth.currentUserPoolUser()

    // check the password correct
    await Auth.changePassword(cognitoUser, password, password)

    await cognitoUser.deleteUser((error: any) => {
      if (error) {
        throw error
      }
    })
    await Auth.signOut()
    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default deleteUser
