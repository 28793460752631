import { Auth } from 'aws-amplify'
import { IConfirmSignUp } from '../../interface'
import localize from './localize'

const confirmSignUp = async (user: IConfirmSignUp) => {
  try {
    await Auth.confirmSignUp(user.email, user.code)

    return {}
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default confirmSignUp
