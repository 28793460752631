import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'

type Props = WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.title}>利用規約</div>
        <p>BeDo+アプリ等基本利⽤規約</p>
        <p>
          BeDo+アプリ等基本利⽤規約（以下「本規約」といいます。）は、株式会社NuJapan（以下「当社」といいます。）が運営提供するアプリ及び投稿ツール（以下「本アプリ等」といいます。）の利⽤に関する条件を定めています。本アプリ等をご利⽤いただくためには、本規約にご同意いただく必要があります。
          <br />
          <br />
          第1条(適⽤)
          <br />
          1.本規約は、本アプリ等の提供条件及び本アプリ等の利⽤に関し、本アプリ等を利⽤するお客様（以下「ユーザー」といいます。）と当社の間の権利義務関係を定めることを⽬的とし、ユーザーと当社との間の本アプリ等の利⽤に関わる⼀切の関係に適⽤されるものとします。
          <br />
          2.本アプリ等上で表⽰される確認事項、注意書き、ガイドラインの他本アプリ等内で提供される個別のコンテンツサービス（以下「個別サービス」といいます。）の個別利⽤規約（以下総称して「個別利⽤規約」といいます。）は、本規約の―部を構成するものとし、ユーザーは、本アプリ等を利⽤するごとに、本アプリ等上に表⽰される個別利⽤規約を確認するものとします。
          <br />
          3.本規約と個別利⽤規約の定めが異なる場合、個別利⽤規約が優先して適⽤されるものとします。
          <br />
          4.ユーザーは、本アプリ等をダウンロードし、本アプリ等を利⽤することにより、本規約及び個別利⽤規約（以下「本規約等」といいます。）の全ての記載内容について同意したものとみなされます。
          <br />
          <br />
          第2条(本規約等の変更)
          <br />
          1.当社は、ユーザーの事前の承諾を得ることなく、本規約等を任意に改定することができるものとします。
          <br />
          2.改定後の本規約等は、特に当社が規定した場合を除き、本アプリ等もしくは当社が運営するウェブサイトに掲⽰したとき、⼜は当社がユーザーに対して告知の案内を配信したときのいずれか早い時期をもって、その効⼒が生じるものとします。
          <br />
          3.ユーザーが、変更後の本規約等に同意できない場合は、直ちに本アプリ等をユーザー⾃⾝のスマートフォン等の携帯端末（以下、「携帯端末」といいます。）からアンインストールするものとします。
          <br />
          4.ユーザーが、本規約等の変更の効⼒が生じた後に本アプリ等を利⽤する場合には、変更後の規約等の全ての記載内容に同意したものとみなされます。
          <br />
          <br />
          第3条（アカウント管理）
          <br />
          1.本アプリ等⼜は個別サービスを利⽤するにあたり、ユーザーが当社からアカウントやパスワード等を配布された場合、ユーザーは、⾃⼰の責任おいて、⾃⼰のアカウントやパスワード等を適切に管理及び保管するものとし、これを第三者に利⽤させ、⼜は貸与、譲渡、名義変更、売買等をしてはならないものとします。
          <br />
          2.当社は、最終のアクセスから１年間以上経過している前項のアカウントを、あらかじめユーザーに通知することなく削除することができるものとします。
          <br />
          3.アカウントやパスワードの管理不⼗分、使⽤上の過誤、第三者の使⽤等によって生じた損害に関する責任はユーザーが負うものとし、当社は⼀切の責任を負わないものとします。
          <br />
          <br />
          第4条（利⽤資格の停⽌）
          <br />
          1.当社は、当社の基準に従って、本アプリ等及び個別サービスの利⽤に関するユーザーの資格の有無を判断し、ユーザーが、以下の各号のいずれかの事由に該当する場合は、本アプリ等及び/⼜は個別サービスの利⽤を拒否することがあり、その理由について⼀切開⽰義務を負わないものとします。
          <br />
          (1)当社に提供した申込情報の全部⼜は⼀部につき虚偽、誤記⼜は記載漏れがあった場合
          <br />
          (2)本規約等に違反する場合
          <br />
          (3)本アプリ等内の⽀払料⾦その他の債務の履⾏を遅滞し、⼜は⽀払を拒否した場合
          <br />
          (4)暴⼒団、暴⼒団員、右翼団体、反社会的勢⼒、その他これらに準ずる者（以下「反社会的勢⼒等」といいます。）である、⼜は資⾦提供その他を通じて反社会的勢⼒等の維持、運営若しくは経営に協⼒若しくは関与する等反社会的勢⼒等との何らかの交流若しくは関与を⾏っていると当社が判断した場合
          <br />
          (5)ユーザーが過去当社との契約（利⽤規約等を含むがこれに限られない）に違反した者⼜はその関係者であると当社が判断した場合
          <br />
          (6)ユーザーが当社の事業と競業する企業⼜は団体等に所属もしくは関係すると当社が判断した場合
          <br />
          (7)ユーザーが当社の事業と同⼀⼜は類似する企業、団体等に所属もしくは関係すると当社が判断した場合
          <br />
          2.当社が前項に定める本アプリ等の利⽤拒否の措置を講じた場合に、当該措置に起因する結果に関し、当社を免責するものとします。
          <br />
          <br />
          第5条（個⼈情報等の取扱い)
          <br />
          1.当社は、ユーザーから取得した個⼈情報等を、当社が別途定める「個⼈情報の取り扱いについて」その他個⼈情報の取り扱いに関して定める⽂書に基づき、適切に取り扱うものとします。
          <br />
          2.本アプリ等及び個別サービスを利⽤する場合、ユーザーが提供する個⼈情報等を当社が「個⼈情報の取り扱いについて」その他個⼈情報の取り扱いに関して定める⽂書に基づいて扱うことに同意したものとします。
          <br />
          3.当社は、本アプリ等において、ユーザーの動向を調査する為に外部ツールを⽤いて、統計的な利⽤情報の取得及び記録をしています。
          <br />
          <br />
          第6条（通信の秘密）
          <br />
          1.当社は、電気通信事業法（昭和59年法律第86号）第4条に基づき、ユーザーの通信の秘密を守ります。
          <br />
          2.当社は、次の各号に掲げる場合には、当該各号に定める範囲内において前項の守秘義務を負わないものとします。
          <br />
          (1)刑事訴訟法（昭和23年法律第131号）⼜は犯罪捜査のための通信傍受に関する法律（平成11年法律第137号）の定めに基づく強制の処分⼜は裁判所の命令が⾏われた場合当該処分⼜は裁判所の命令の定める範囲内
          <br />
          (2)法令に基づく強制的な処分が⾏われた場合当該処分⼜は命令の定める範囲内
          <br />
          (3)特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開⽰に関する法律（平成13年法律第137号）第4条に基づく開⽰請求の要件が充⾜されたと当社が判断した場合当該開⽰請求の範囲内
          <br />
          (4)他⼈の⽣命、⾝体⼜は財産の保護のために必要があると当社が判断した場合他⼈の⽣命、⾝体⼜は財産の保護のために必要な範囲内
          <br />
          <br />
          第7条（本アプリ等の提供）
          <br />
          1.本アプリ等及び個別サービスに関連する⼀切の権利（著作権、商標権、特許権等の知的財産権を含みますが、これらに限りません。）は、当社⼜は当社に権利許諾した第三者に帰属します。
          <br />
          2.当社は、ユーザーに対し、本規約等に従って本アプリ等及び個別サービスを利⽤する権利を許諾します。ユーザーは、本アプリ等及び個別サービスを利⽤する権利を第三者に譲渡したり相続させたりすることはできないものとします。
          <br />
          3.ユーザーは、本アプリ等及び個別サービス内のコンテンツを個⼈的な利⽤のみを⽬的として閲覧するものとし、商業⽬的などで不特定多数にコンテンツを閲覧させることや、コンテンツを譲渡、複製、貸与、公衆送信などすること、⼜はこれらの⾏為を第三者に⾏わせることはできないものとします。
          <br />
          4.ユーザーは、本アプリ等及び個別サービスを利⽤するにあたり、携帯電話機、通信機器、通信⼿段及び電⼒などをユーザーの費⽤と責任で用意するものとし、その利⽤にかかる費⽤はユーザーが負担するものとします。
          <br />
          5.当社は、本アプリ等及び個別サービスが全ての携帯端末に対応することを保証するものではありません。なお、タブレット端末による利⽤は可能ですが、⾮推奨となっています。
          <br />
          6.本アプリ等はあらゆるスマートフォン⽤OSに対応していません。当社が別途指定するOSを確認するものとします。
          <br />
          7アプリ上での会話を通じ、何らかの異常や体調不良を感じた場合には医師に相談するものとします。また、精神疾患等により医師の治療・指導等を受けている⽅、もしくはそのおそれがある⽅は必ず医師に相談し、指⽰を仰いだ上で本アプリ等を利⽤するものとします。
          <br />
          <br />
          第8条（第三者のサービス及びコンテンツ）
          <br />
          1.本アプリ等及び個別サービスは、当社の提携先等の第三者が提供するサービス⼜はコンテンツ（以下「パートナーサービス」といいます。）を含む場合があります。かかるパートナーサービスに対する責任は、これを提供する事業者が負うものとし、当社は負わないものとします。また、かかるパートナーサービスには、これを提供する事業者が定める利⽤規約、プライバシーポリシーその他の条件（名称は問いません。）が適⽤されることがあります。
          <br />
          2.ユーザーがパートナーサービスを利⽤する場合、当社はパートナー事業者に対してユーザーのアカウント情報を提供する場合があり、ユーザーはアカウント情報の提供に予め同意するものとします。
          <br />
          3.当社は、パートナー事業者から、ユーザーのパートナーサービスの利⽤履歴その他の情報の提供を受ける場合があり、ユーザーは当該情報の提供に予め同意するものとします。
          <br />
          4.ユーザーが本アプリ等上のチャット機能を利⽤してパートナー事業者とチャットを行った場合、当社は当該チャットのログを以下の⽬的に必要な範囲で閲覧及び利⽤することができるものとし、ユーザーはこれに同意するものとします。
          <br />
          (1)本アプリ等、個別サービス及びパートナーサービスの改善、新サービスの開発のため
          <br />
          (2)本アプリ等、個別サービス及びパートナーサービスに関するお問い合わせ等への対応のため
          <br />
          (3)本規約等に違反する⾏為に対する対応のため
          <br />
          (4)その他上記利⽤⽬的に付随する⽬的のため
          <br />
          5.パートナーサービスの利⽤に関してユーザーとパートナー事業者その他の第三者との間で生じた取引、連絡、紛争等については、ユーザーの費⽤と責任において処理及び解決するものとし、当社はかかる事項について⼀切責任を負わないものとします。
          <br />
          6.ユーザーが、AppleiOS⼜はAndroid等のアプリストアを搭載する携帯端末を使⽤して本アプリ等を利⽤する場合、AppleInc.⼜はGoogle等それぞれが本契約の第三受益者となるものとします。この場合、該当する第三受益者のサービス規約に定められた利⽤条件に同意していることが前提であり、ユーザーは、同規約を遵守するものとします。
          <br />
          7.ユーザーが、本アプリ等内で課⾦決済を行った場合、ユーザーが利⽤している端末に応じて、第三者（Apple社またはGoogle社が含まれるが、これに限られない。）の決済に関する規約に同意していることが前提であり、ユーザーは、同規約を遵守するものとします。
          <br />
          <br />
          第9条（チャット相談サービスの利⽤）
          <br />
          1.本アプリ等内のチャットサービスは、当社が委託したカウンセラー、コーチなど（以下「専⾨家」といいます。）が、チャットで、各専⾨家の専⾨領域に係る⼀般的な情報を提供するチャット相談サービスをいい、ユーザーへ情報を通知する機能も有しています。
          <br />
          2.本アプリ等内のチャットサービスは、当社及び当社の委託先（当社⼦会社及び専⾨家）、お客様の三者間でチャット及び情報を共有するグループチャットとして設計されています。
          <br />
          3.チャットにおける相談の回答は、診療⾏為を伴わずに行う⼀般的な助⾔の範囲内のものであり、ユーザーの具体的な症状等を踏まえた問診、診断、治療等を行う医療⾏為ではありません。ユーザーの具体的な症状等については、医師に相談のうえ、専⾨的な診断を受けるものとします。
          <br />
          4.チャットにおける相談内容と回答内容については、個⼈情報や個⼈の特定に繋がるような情報を削除した上で、「個⼈情報の取り扱いについて」その他個⼈情報の取り扱いに関して定める⽂書の利⽤⽬的に即して利⽤する場合があります。
          <br />
          5.本規約第11条（禁⽌事項）に関する相談等の場合は、警察に情報提供する場合があります。
          <br />
          6.ユーザーのチャットにおける相談内容がユーザー⾃⾝の⽣命⾝体に関わるもので、緊急対応が必要と当社が判断する場合、警察、ユーザーの所属企業⼜は団体等に緊急連絡する場合があります。
          <br />
          7.チャットにおける相談内容には、はっきりとした住所・電話番号等個⼈が簡単に特定できる情報は記⼊しないものとします。
          <br />
          8.以下の各号に定めるチャット相談については回答できません。
          <br />
          (1)緊急の対応が必要な相談
          <br />
          (2)病名の診断や治療⽅法の提⽰、現在受けている医療の是⾮の判断等、医療⾏為にあたる内容、並びに法律、税務等専⾨的知識を必要とする相談
          <br />
          (3)⼀般⽤医薬品以外の医療⽤医薬品または要指導医薬品に関する薬剤師への相談
          <br />
          (4)既に裁判所で係争中の事案、既に別の弁護⼠へ依頼済みの事案についての弁護⼠への相談
          <br />
          (5)法令に違反する相談、業務妨害、脅迫的⾔動、誹謗、中傷、もしくは性的嫌がらせ等に直接間接に関係する相談、相談と判断できないもの、その他禁⽌⾏為に関する相談
          <br />
          <br />
          第10条（回答に関する免責事項）
          <br />
          1.チャットにおける回答は誠意を持って⾏いますが、相談者の状況が確実に改善することは保証できません。また回答を元に相談者が取られた⾏動やその結果に関して、当社は⼀切責任を負いません。
          <br />
          2.チャットにおける回答はできるだけ速やかに行うよう努めますが、相談者の希望に沿う保証はできません。回答の遅延によって生じたいかなる結果についても、当社は責任を負いません。
          <br />
          3.専⾨家は匿名で回答することがあります。
          <br />
          <br />
          第11条（禁⽌事項）ユーザーは、本アプリ等⼜は個別サービスの利⽤にあたり、以下の各号のいずれかに該当する⼜は該当するおそれがあると当社が判断する⾏為をしてはならないものとします。
          <br />
          (1)法令に違反する⾏為⼜はそのおそれのある⾏為 (2)公序良俗に反する⾏為
          <br />
          (3)政治的⼜は宗教的思想を含む情報を提供する⾏為及び政治的⼜は宗教的な勧誘を⾏う⾏為
          <br />
          (4)他のユーザー、第三者⼜は当社に対し、財産権(知的財産権を含みます。)の侵害、名誉・プライバシーの侵害、誹謗中傷、その他の不利益を与える⾏為もしくはそのおそれのある⾏為
          <br />
          (5)本アプリ等⼜は個別サービスを複製⼜は複写する⾏為
          <br />
          (6)本アプリ等⼜は個別サービスを改変し、もしくはリバースエンジニアリング(主に、内容を解析して⼈間が読み取り可能な形に変換する⾏為)、逆コンパイル、逆アセンブルする⾏為
          <br />
          (7)本アプリ等⼜は個⼈サービスのデータを操作⼜は変更する⾏為
          <br />
          (8)本アプリ等⼜は個別サービスに影響を与える外部ツールの利⽤・作成・頒布・販売等を⾏う⾏為
          <br />
          (9)本アプリ等⼜は個別サービスの運営を妨害する⾏為
          <br />
          (10）当社が本来意図しない動作や外部ツールを意図的に利⽤して、本アプリ等⼜は個別サービス内でのポイント等その他の利益を得ようとする⾏為
          <br />
          (11）サーバその他当社のコンピュータに不正にアクセスする⾏為
          <br />
          (12）虚偽の登録内容等を申請する⾏為
          <br />
          (13）⼀⼈で複数のアカウントを発⾏保有する⾏為（ただし、当社が別に許可したものは除く。）
          <br />
          (14）複数⼈で⼀つのアカウントを共同保有する⾏為（ただし、当社が別に許可したものは除く。）
          <br />
          (15）携帯端末等を貸与して⾏う操作⾏為
          <br />
          (16）第三者になりすまして本アプリ等⼜は個別サービスを利⽤する⾏為
          <br />
          (17）本アプリ等⼜は個別サービスの他のユーザーのID⼜はパスワードを利⽤する⾏為
          <br />
          (18）ユーザー本⼈以外が所有するクレジットカードを不正使⽤した本アプリ等⼜は個別サービスの利⽤
          <br />
          (19）個別サービス（検査器等）、アカウント、クーポンコード等を転売その他譲渡する⾏為
          <br />
          (20）ユーザーが当社の競業先⼜は競業先に所属し、本アプリ等もしくは個別サービスを調査する⽬的で利⽤する⾏為、競合するサービスを宣伝する⾏為
          <br />
          (21）本アプリ等及び個別サービスの利⽤⽬的と合致しない外部ウェブサイトへのハイパーリンクを貼り付ける⾏為
          <br />
          (22)異性交際に関する情報を提供する⾏為
          <br />
          (23)本アプリ等⼜は個別サービスの他のユーザーのIDもしくはパスワードを利⽤する⾏為
          <br />
          (24)他のユーザーに対する脅迫、嫌がらせ等（スパム⾏為を含む。）にあたる⾏為
          <br />
          (25)差別的、わいせつ的なコンテンツや暴⼒を誘発するようなコンテンツ等を投稿するなど⼈を不快にさせ⾏為
          <br />
          (26)ユーザー本⼈以外が所有するクレジットカードを不正使⽤した本アプリ等⼜は個別サービスの利⽤
          <br />
          (27)本アプリ等⼜は個別サービスを通じた営利⽬的⾏為もしくはその準備⽬的⾏為
          <br />
          (28)本アプリ等⼜は個別サービスを模倣したアプリもしくは個別サービスの制作、準備を⽬的とした利⽤⾏為
          <br />
          (29)当社が本アプリ等⼜は/及び個別サービスの利⽤を終了させた場合、当社の許可なくアプリアカウントを発⾏保有する⾏為
          <br />
          (30)前各号の⾏為を直接もしくは間接に惹起し、⼜は容易にする⾏為
          <br />
          (31)その他当社が不適切と判断する⾏為
          <br />
          <br />
          第12条（本規約等違反⾏為に対する対処）
          <br />
          1.ユーザーが本規約等のいずれかに違反していると当社が判断した場合、当社は、いつでも当該ユーザーによる本アプリ等⼜は/及び個別サービスの利⽤を終了させることができる他以下の各号に定める処分を講ずることができるものとします。当該処分によりユーザーに不利益・損害が発⽣した場合においても、当社はその責任を負わないものとします。
          <br />
          (1)本規約等に違反する⾏為を差し止め、同様の⾏為を繰り返さないことを要求すること
          <br />
          (2)第14条で定める投稿情報の⾃発的削除・訂正を求めること
          <br />
          (3)第14条で定める投稿情報、履歴等の情報の全部もしくは⼀部を削除し、公開範囲を変更し、または閲覧できない状態（⾮公開）にすること
          <br />
          (4)刑事事件、⾏政事件その他に該当する可能性がある場合の警察その他の公的機関への通報を含む、違反事実の本サービス内外での開⽰
          <br />
          (5)⼀時的⼜は永続的に本アプリ等⼜は/及び個別サービスの利⽤を停⽌すること
          <br />
          2.ユーザーは、当社が本規約等に基づいて行った本規約等に違反する⾏為への対処について、異議を申し⽴てることはできないものとします。
          <br />
          3.ユーザーは、本アプリ等⼜は個別サービスを利⽤したことに起因して（当社がかかる利⽤を原因とするクレームを第三者より受けた場合を含みます。）、当社が直接的⼜は間接的に何らかの損害（弁護⼠費⽤の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを補償するものとします。
          <br />
          <br />
          第13条（権利帰属）本アプリ等及び個別サービスに関する知的財産権は全て当社⼜は当社にライセンスを許諾している者に帰属しており、本規約等に基づく本アプリ等及び個別サービスの利⽤許諾は、本アプリ等⼜は個別サービスに関する当社⼜は当社にライセンスを許諾している者の知的財産権の使⽤許諾を意味するものではないものとします。
          <br />
          <br />
          第14条（投稿情報の使⽤許諾）
          <br />
          1.投稿情報の権利（著作権および著作者⼈格権等の周辺権利）は、創作したユーザーに帰属します。但し、ユーザーは著作者⼈格権を⾏使しないものとします。
          <br />
          2.当社は、投稿情報を、本アプリ等及び個別サービスの円滑な提供、当社システムの構築、改良、メンテナンスその他サービスの向上に必要な範囲内で、無償で⾮独占的に使⽤することができるものとし、ユーザーが退会した場合であっても当該使⽤許諾の効⼒は失われないものとします。
          <br />
          3.当社が前項に定める形で投稿情報を使⽤するにあたっては、投稿情報の⼀部⼜は⽒名表⽰を省略することができるものとします。
          <br />
          4.投稿情報が第三者の権利を侵害した場合、⼜は投稿情報に起因したクレームや請求については、ユーザーの費⽤と責任でこれを解決処理するものとします。
          <br />
          5.前項のクレームや請求への対応に関連して当社に費⽤が発⽣した場合⼜は賠償⾦などの⽀払いを⾏った場合は、当該費⽤および賠償⾦、当社が⽀払った弁護⼠費⽤等を当該ユーザーの負担とし、当社は、当該ユーザーにこれらの合計額の⽀払いを請求できるものとします。
          <br />
          <br />
          第15条（当社の削除権限）
          <br />
          1.当社は、本規約等に違反する⼜は違反する恐れがあると当社が判断する投稿があった場合、ユーザーへの事前通知なく、投稿情報の全部⼜は⼀部の削除もしくは公開範囲の変更等の不利益な措置を⾏うことができるものとします。
          <br />
          2.次に掲げる場合には、当社は投稿情報の違法性・規約等違反の有無に関わらず、関連する投稿情報について、その全部もしくは⼀部の削除⼜は公開範囲の変更等の不利益な措置を⾏うことができるものとします。
          <br />
          (1)権利者と称する者から、投稿情報が⾃分の権利（例えば、知的財産権、財産権、名誉、信⽤、肖像権をいうが、これに限られない。）を侵害する旨の申告があった場合。ただし、権利者と称する者から、権利者であることを合理的に判断できる資料を提⽰され、当社にて慎重に検討した結果、権利者であると当社が判断した場合に限る。
          <br />
          (2)公的な機関⼜は専⾨家（国、地⽅公共団体、弁護⼠等をいいます。）から、投稿情報について、違法、公序良俗違反⼜は他⼈の権利を侵害する等の指摘・意⾒表明があった場合。
          <br />
          (3)⼈（実在の⼈物であるか否かを問わず、漫画・アニメ等のキャラクターを含みます。）の裸体（着⾐の全部⼜は⼀部を⽋くものをいいます。）を撮影・描写した情報が投稿された場合。
          <br />
          (4)その他当社が法令違反、公序両属違反等投稿情報を不適切と判断した場合。
          <br />
          <br />
          第16条(退会)
          <br />
          1.ユーザーは、⾃⼰の⾃由な意思で退会⼜は本アプリ等のアンインストールをできるものとします。ユーザーが本アプリ等のアンインストールを行った場合に、退会したものとみなします。
          <br />
          2.退会にあたり、当社に対して負っている債務がある場合は、ユーザーは、当社に対して負っている債務の⼀切について当然に期限の利益を失い、直ちに当社に対して全ての債務の⽀払を行うものとします。
          <br />
          3.当社は、退会したユーザーの個⼈情報や投稿情報等の⼀切の情報を引続き保有する義務を負わないものとします。
          <br />
          4.前第2項及び第3項の規定は、第4条（利⽤資格の停⽌）で定める場合にも準⽤されるものとします。
          <br />
          <br />
          第17条(保証の否認及び免責)
          <br />
          1.当社は、本アプリ等及び個別サービス（投稿情報、商品役務等に関する⼀切の情報を含むがこれに限られない。）がユーザーの特定の⽬的に適合すること、期待する機能・商品的価値・正確性・有⽤性を有すること、ユーザーによる本アプリ等の利⽤がユーザーに適⽤のある法令⼜は業界団体の内部規則等に適合すること、及び不具合が⽣じないことについて、何ら保証するものではなく、本アプリ等及び個別サービスに関してユーザーが被った損害(以下「ユーザー損害」といいます。)につき、当社は法令に定めがある場合を除いて、賠償する責任を⼀切負わないものとします。
          <br />
          2.当社は、本アプリ等及び個別サービスのバグその他を補修する義務、本アプリ等並びに個別サービスを改良⼜は改善する義務を負わないものとします。
          <br />
          3.当社は、ユーザーに事前に通知することなく、当社の都合により、本アプリ等及び個別サービスの全部⼜は⼀部の提供の中断、停⽌、終了、変更をすることができるものとします。①当社による本アプリ等及び個別サービスの全部⼜は⼀部の提供の中断、停⽌、終了、利⽤不能もしくは変更、②ユーザーが本アプリ等に送信したメッセージ⼜は投稿情報の削除・消失、③アカウントの抹消、④パスワードの紛失・忘却、⑤携帯端末の紛失・盗難・故障、⑥本アプリ等⼜は個別サービスの利⽤によるアカウントデータの消失、⑦機器の故障若しくは損傷その他本アプリ等及び個別サービスに関してユーザーが被った損害(以下「ユーザー損害」といいます。)につき、当社は法令に定めがある場合を除いて、賠償する責任を⼀切負わないものとします。
          <br />
          4.当社は、本アプリ等及び個別サービス内で、適宜情報、コンテンツ、検査サービスの提供やアドバイスを行うことがありますが、その結果について、法令に定めがある場合を除いて、賠償する責任を⼀切負わないものとします。
          <br />
          5.ユーザーに本アプリ等のアップデート版またはバージョンアップ情報等を提供する場合があります。この場合、かかるアップデート版またはバージョンアップ情報等も本アプリ等及び個別サービスとして扱い、これらにも本規約等が当然に適⽤されます。
          <br />
          6.何らかの理由により当社が責任を負う場合であっても、当社は、ユーザー損害につき、過去3か⽉間にユーザーが当社に⽀払った⾦額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。但し、当社に故意⼜は重過失がある場合を除きます。
          <br />
          7.本アプリ等、個別サービス⼜は当社ウェブサイトに関連してユーザーと他のユーザー⼜は第三者との間において生じた取引、連絡、紛争等については、当社は⼀切責任を負わないものとします。
          <br />
          <br />
          第18条(ユーザーの地位の譲渡等)
          <br />
          1.ユーザーは、当社の書⾯による事前の承諾なく、ユーザーの地位⼜は本規約等に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできないものとします。
          <br />
          2.当社は本アプリ等及び個別サービスに係る事業を他社に譲渡した場合には、当該事業譲渡に伴い本規約等の地位、本規約等に基づく権利及び義務並びにユーザーの登録事項その他情報を当該事業譲渡の譲受⼈に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
          <br />
          <br />
          第19条(権利の放棄)
          <br />
          当社が本規約等に示される権利を⾏使⼜は実施しない場合にも、当該権利を放棄するものではないものとします。
          <br />
          <br />
          第20条(分離可能性)
          <br />
          本規約等のいずれかの規定が、消費者契約法その他の法令等により無効⼜は執⾏不能と判断された場合であっても、無効⼜は執⾏不能と判断された規定以外の残りの部分は、継続して完全に効⼒を有するものとします。
        </p>

        <p>以上 （附則） 制定⽇：令和2年6⽉18⽇</p>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      padding: 20,

      '& p': {
        fontSize: 13,
        marginBottom: 16,
      },
    },

    title: {
      fontSize: 18,
      paddingTop: 20,
      paddingBottom: 40,
      textAlign: 'center',
    },
  })

export default withStyles(useStyles)(Index)
