import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'

type Props = WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.title}>プライバシーポリシー</div>

        <p>NU Japanプライバシーポリシー</p>
        <p>
          本プライバシーポリシーは、お客様が株式会社 NU
          Japan（以下「当社」といいます。）の運営する各サービス（以下「各サービス」といいます。）をご利用されたことに伴い、当社が取得したお客様の個人情報および個人情報以外のパーソナル情報（以下総称して「プライバシー情報」といいます。）の取扱い方針を定めるものです。当社は、各サービスをご利用いただくお客様をはじめとする当社に情報を提供される全ての方々のプライバシー情報の重要性を認識するとともに、個人情報保護に関する法令およびその他の規範を遵守し、自主的なルールおよび体制を確立し、プライバシー情報の保護に細心の注意を払っております。
          <br />
          <br />
          「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、メールアドレスその他の記述等により特定の個人を識別できる情報を指します。
          <br />
          「個人情報以外のパーソナル情報」とは、情報それ自体単体で個人情報にあたらないが、個人情報と連結可能な生存する個人に関する情報をいいます。
        </p>

        <p>
          ■ 当社が取得する情報について
          <br />
          当社は、各サービスにおいて、適法かつ公正な手段によって以下に定めるお客様のプライバシー情報を取得します。
          <p>
            （1）お客様にご提供いただく情報
            <ul>
              <li>
                氏名、メールアドレス、生年月日、その他当社が定める入力フォームに入力いただいた情報
              </li>
              <li>投稿情報</li>
              <li>チャット相談サービスにおける相談内容及び回答情報</li>
            </ul>
          </p>
          <p>
            （2）お客様のサービス利用情報 cookie 情報（＊1）
            <ul>
              <li>各サービスのログ情報、購買情報、決済情報</li>
              <li>各サービス内でのお客様のアクションに関する情報</li>
              <li>端末情報（端末識別子、携帯端末識別子および IP アドレス）</li>
              <li>位置情報（携帯電話等から送信される位置情報）</li>
            </ul>
            ＊1「cookie」の使用について：当社は、サイトをご利用の際に、cookie
            と呼ばれる情報をご利用のコンピューターに送る場合があり、その情報はハードディスクまたはメモリーに保存されます。cookieはアクセス情報の統計処理など、より良いサービスを提供する目的で使用するものであり、お客様のプライバシー、または利用環境を侵すことはありません。また、いかなる場合においてもアクセスした方を個別に識別できる状態で情報公開することはありま
            せん。
          </p>
        </p>

        <p>
          ■ 利用目的について
          <br />
          当社は、取得したお客様のプライバシー情報を以下の目的のために利用します。
          <ul>
            <li>
              当社のアプリ、ウェブサイト、コンテンツの開発・提供をするため
            </li>
            <li>
              言語化習慣・ウェルビーイング・助言等のカウンセリングサービスを提供するため
            </li>
            <li>
              企業の健康経営を目的とした従業員のウェルビーイングに関するコンサルティングサービスを提供するため
            </li>
            <li>
              企業のウェルビーイング/健康経営に関する調査サービスを提供するため
            </li>
            <li>その他当社の各商品・サービスを提供するため</li>
            <li>本人確認、認証サービスのため</li>
            <li>当社の商品、サービスの料金を請求するため</li>
            <li>
              当社または第三者の商品・サービスの広告の開発、配信または提供およびこれらの成果確認、分析のため
            </li>
            <li>マーケティング調査、統計、分析のため</li>
            <li>アンケートの実施のため</li>
            <li>懸賞、キャンペーンの実施のため</li>
            <li>システムメンテナンス、不具合対応のため</li>
            <li>当社商品・サービスの改善、新商品サービスの開発改善のため</li>
            <li>学術研究、分析研究のため</li>
            <li>
              当社または当社とサービスを共同して開発提供する第三者あるいは当社と共同でキャンペーンを実施する第三者、当社とセミナー等のイベントを共催する第三者、その他パートナー企業の商品、サービス、キャンペーン等に関する情報を提供するため
            </li>
            <li>
              当社の各サービスに関連して個人を特定できない形に加工した上で、ウェルビーイングに関するレポートを作成し、学術目的や商業目的での発表を行うため
            </li>
            <li>
              当社が定める利用規約、ポリシー(以下「規約等」といいます。)の変更などの重要な通知を送るため
            </li>
            <li>規約等に違反する行為に対する対応するため</li>
            <li>問い合わせに対応するため</li>
            <li>その他当社の各サービスにおいて定める目的のため</li>
          </ul>
        </p>

        <p>
          ■ 第三者提供
          <br />
          当社はお客様のプライバシー情報を以下の場合を除いて第三者に提供することはございません。
          <ul>
            <li>お客様の同意がある場合</li>
            <li>法令に基づく場合</li>
            <li>
              裁判所、検察庁、警察、税務署、弁護士会またはこれらに準じた権限を持つ機関から開示を求められた場合
            </li>
            <li>
              人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
            </li>
            <li>
              合併、会社分割、事業譲渡その他の事由によってプライバシー情報の提供を含む当社の事業の承継が行われる場合
            </li>
            <li>
              プライバシー情報を匿名化または統計化した上で、提携先等の第三者に開示または提供する場合
            </li>
            <li>その他当社の各サービスにおいて個別に定める場合</li>
          </ul>
        </p>

        <p>
          ■ 外部委託
          <br />
          当社は、利用目的の達成に必要な範囲で、取得したプライバシー情報の全部または一部を第三者へ委託する場合があります。この場合は、当社が定めた基準を満たす者を委託先として選定するとともに、契約にあたって守秘義務に関する事項を規定し、情報が安全に管理される措置を施します。
        </p>

        <p>
          ■ 情報管理体制
          <br />
          当社は、セキュリティー委員会を設置し、同委員会においてプライバシー情報の取扱いに関する方針を決定し、運用状況を確認し、プライバシー情報の適法かつ公正な管理を実施致します。また、役員および従業員に対して研修や教育機会を提供し、コンプライアンスの精神を浸透させて日常業務におけるプライバシー情報の適正な取扱いを徹底します。
        </p>

        <p>
          ■ 改定
          <br />
          本プライバシーポリシーは改定されることがあります。重要な変更にあたってはお客様に対してわかりやすい方法にて改定内容を告知致します。
        </p>

        <p>
          ＜個人情報の取扱いその他ポリシーに関するお問い合わせおよび苦情窓口＞
          <br />
          〒066-0054
          <br />
          北海道千歳市柏陽 1 丁目 11 番地の 9 株式会社 NU Japan
          <br />
          個人情報保護管理事務局 E-mail：nujapanmeeting@gmail.com
          <br />
          制定年月日：令和 2 年 6 月 18 日
        </p>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      padding: 20,

      '& p': {
        fontSize: 13,
        marginBottom: 16,
      },

      '& ul': {
        paddingLeft: 32,
      },
    },

    title: {
      fontSize: 18,
      paddingTop: 20,
      paddingBottom: 40,
      textAlign: 'center',
    },
  })

export default withStyles(useStyles)(Index)
