import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Actions } from './action'
import { IState } from './interface'

export const initialState: IState = {
  id: undefined,
  email: undefined,
  username: undefined,

  signup: false,
  confirmed: false,
  fetched: false,
  restored: false,
  reset: false,
}

export const Reducer = reducerWithInitialState<IState>(initialState)
  // fetch
  .case(Actions.fetch.started, (state, payload) => {
    return { ...state, fetched: false, error: null }
  })
  .case(Actions.fetch.done, (state, payload) => {
    return { ...state, ...payload.result, fetched: true, error: null }
  })
  .case(Actions.fetch.failed, (state, error) => {
    return { ...state, fetched: true, ...error }
  })

  // refresState
  .case(Actions.refresState, (state, payload) => {
    return { ...state, ...initialState, error: null }
  })

  // signin
  .case(Actions.signin.started, (state, payload) => {
    return { ...state, ...payload, error: null }
  })
  .case(Actions.signin.done, (state, payload) => {
    return { ...state, ...payload.result, error: null }
  })
  .case(Actions.signin.failed, (state, error) => {
    return { ...state, ...error }
  })

  // signinWithLine
  .case(Actions.signinWithLine.started, (state, payload) => {
    return { ...state, ...payload, error: null }
  })
  .case(Actions.signinWithLine.done, (state, payload) => {
    return { ...state, ...payload.result, error: null }
  })
  .case(Actions.signinWithLine.failed, (state, error) => {
    return { ...state, ...error }
  })

  // signup
  .case(Actions.signup.started, (state, payload) => {
    return { ...state, ...payload, confirmed: false, error: null }
  })
  .case(Actions.signup.done, (state, payload) => {
    return { ...state, ...payload.result, signup: true, error: null }
  })
  .case(Actions.signup.failed, (state, error) => {
    return { ...state, ...error }
  })

  // confirmSignUp
  .case(Actions.confirmSignUp.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.confirmSignUp.done, (state, payload) => {
    return { ...state, confirmed: true, error: null }
  })
  .case(Actions.confirmSignUp.failed, (state, error) => {
    return { ...state, ...error }
  })

  // resendSignUp
  .case(Actions.resendSignUp.started, (state, payload) => {
    return { ...state, resend: false, error: null }
  })
  .case(Actions.resendSignUp.done, (state, payload) => {
    return { ...state, resend: true, error: null }
  })
  .case(Actions.resendSignUp.failed, (state, error) => {
    return { ...state, ...error }
  })

  // restore
  .case(Actions.restore.started, (state, payload) => {
    return { ...state, ...payload, reset: false, restored: false, error: null }
  })
  .case(Actions.restore.done, (state, payload) => {
    return { ...state, ...payload.result, restored: true, error: null }
  })
  .case(Actions.restore.failed, (state, error) => {
    return { ...state, ...error }
  })

  // resetPassword
  .case(Actions.resetPassword.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.resetPassword.done, (state, payload) => {
    return { ...state, reset: true, error: null }
  })
  .case(Actions.resetPassword.failed, (state, error) => {
    return { ...state, ...error }
  })

  // refreshErrors
  .case(Actions.refreshErrors, (state, payload) => {
    return { ...state, error: null }
  })

  // setEmailToParam
  .case(Actions.setEmailToParam, (state, payload) => {
    return { ...state, params: { ...payload }, error: null }
  })

  // signout
  .case(Actions.signout.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.signout.done, (state, payload) => {
    return { ...state, ...initialState, signout: true, error: null }
  })
  .case(Actions.signout.failed, (state, error) => {
    return { ...state, ...error }
  })

  // updateProfile
  .case(Actions.updateProfile.started, (state, payload) => {
    return { ...state, processing: true, processed: false, error: null }
  })
  .case(Actions.updateProfile.done, (state, payload) => {
    return {
      ...state,
      processing: false,
      processed: true,
      ...payload.result,
      error: null,
    }
  })
  .case(Actions.updateProfile.failed, (state, error) => {
    return { ...state, processing: false, ...error }
  })

  // verifyCurrentUserEmail
  .case(Actions.verifyEmail.started, (state, payload) => {
    return { ...state, processing: true, processed: false, error: null }
  })
  .case(Actions.verifyEmail.done, (state, payload) => {
    return {
      ...state,
      ...initialState,
      confirmed: true,
      error: null,
    }
  })
  .case(Actions.verifyEmail.failed, (state, error) => {
    return { ...state, processing: false, ...error }
  })

  // changePassword
  .case(Actions.changePassword.started, (state, payload) => {
    return { ...state, processing: true, processed: false, error: null }
  })
  .case(Actions.changePassword.done, (state, payload) => {
    return {
      ...state,
      confirmed: true,
      processing: false,
      processed: true,
      error: null,
    }
  })
  .case(Actions.changePassword.failed, (state, error) => {
    return { ...state, processing: false, ...error }
  })

  // deleteUser
  .case(Actions.deleteUser.started, (state, payload) => {
    return { ...state, processing: true, processed: false, error: null }
  })
  .case(Actions.deleteUser.done, (state, payload) => {
    return {
      ...state,
      processing: false,
      processed: true,
      error: null,
    }
  })
  .case(Actions.deleteUser.failed, (state, error) => {
    return { ...state, processing: false, ...error }
  })
