import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import {
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@material-ui/core'

interface IIOwnProps {
  numAll: number
  numFavorite: number
  activeBadge: string
  setActiveBadge: React.Dispatch<React.SetStateAction<string>>
  setSearchStr: React.Dispatch<React.SetStateAction<string>>
}

export const ACTIVE_BADGE_ALL = 'all'
export const ACTIVE_BADGE_FAVORITE = 'favorite'

type Props = IIOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  const [onSearch, setOnSearch] = React.useState(false)
  const handleCloseSearch = () => {
    props.setSearchStr('')
    setOnSearch(false)
  }
  const handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchStr(event.target.value)
  }

  return (
    <React.Fragment>
      <div className={`${classes.root} ${onSearch ? 'active' : ''}`}>
        {!onSearch ? (
          <div className={classes.badgeSearchContainer}>
            <div className={classes.badgeContainer}>
              <Button
                className={`${classes.badge} ${
                  props.activeBadge === ACTIVE_BADGE_ALL ? 'active' : ''
                }`}
                classes={{ label: classes.buttonLabel }}
                onClick={() => {
                  props.setActiveBadge(ACTIVE_BADGE_ALL)
                }}
              >
                <span>すべて</span>
                <div>{props.numAll}</div>
              </Button>
              <Button
                className={`${classes.badge}  ${
                  props.activeBadge === ACTIVE_BADGE_FAVORITE ? 'active' : ''
                }`}
                classes={{ label: classes.buttonLabel }}
                onClick={() => {
                  props.setActiveBadge(ACTIVE_BADGE_FAVORITE)
                }}
              >
                <span>お気に入り</span>
                <div>{props.numFavorite}</div>
              </Button>
            </div>
            <div
              onClick={() => {
                setOnSearch(true)
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7.5"
                  cy="7.5"
                  r="6.5"
                  stroke="#333333"
                  strokeWidth="2"
                />
                <line
                  x1="14.4142"
                  y1="14"
                  x2="18"
                  y2="17.5858"
                  stroke="#333333"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div>
            <OutlinedInput
              id="outlined-adornment-password"
              color="primary"
              placeholder="天気を検索"
              classes={{ root: classes.inputRoot, input: classes.input }}
              onChange={handleOnChangeSearch}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleCloseSearch} edge="end">
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1.50012"
                        y1="13.6571"
                        x2="12.8138"
                        y2="2.34337"
                        stroke="#B073E5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <line
                        x1="12.8137"
                        y1="13.657"
                        x2="1.49998"
                        y2="2.34328"
                        stroke="#B073E5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      padding: 20,
      position: 'absolute',
      top: 0,
      width: '100%',
      background: constants.COLOR_SECOND,
      borderBottom: `1px solid #E1DEEB`,
      zIndex: 1000,
      '&.active': {
        boxShadow: '0 1px 3px 0 rgba(188, 109, 236, 0.15)',
      },
    },
    badgeSearchContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 40,
    },
    badgeContainer: {
      display: 'flex',
    },
    badge: {
      height: 30,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 15,
      padding: '4px 10px',
      color: '#333',
      border: '1px solid #E1DEEB',
      marginRight: 10,

      '&.active ': {
        color: '#fff',
        background: constants.COLOR_MAIN,
        '& div': {
          background: '#9B50DC',
        },
      },

      '& span': {
        marginRight: 4,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      },
      '& div': {
        background: '#E1DEEB',
        borderRadius: 10,
        padding: '0 8px',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      },
    },

    inputRoot: {
      width: '100%',
      borderRadius: 20,
      height: 40,
    },

    input: {
      color: constants.COLOR_MAIN,
      '&::placeholder': {
        color: constants.COLOR_MAIN_LIGHT,
      },
    },

    buttonLabel: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
  })

export default withStyles(useStyles)(Index)
