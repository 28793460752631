import { createStore, compose, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import epics from './epics'
import actions from './actions'
import reducers, { RootState } from './reducers'
import { ActionType } from 'typesafe-actions'

interface IExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
}
declare var window: IExtendedWindow

export type RootStateType = RootState
export type ActionsType = ActionType<typeof actions>

const epicMiddleware = createEpicMiddleware<
  ActionsType,
  ActionsType,
  RootState
>()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore(initialState?: RootStateType) {
  const middlewares = [epicMiddleware]
  const enhancer = composeEnhancers(applyMiddleware(...middlewares))
  return createStore(reducers, initialState, enhancer)
}

const store = configureStore()
epicMiddleware.run(epics)

export { store, actions }
