import { Auth } from 'aws-amplify'
import localize from './localize'

const currentSession = async () => {
  try {
    const res = await Auth.currentSession()

    const idToken = res.getIdToken()
    let emailVerified = idToken.payload.email_verified
    if (idToken.payload.identities && idToken.payload.identities.length > 0) {
      emailVerified = true
    }

    return {
      id: idToken.payload.sub,
      username: idToken.payload.nickname,
      email: idToken.payload.email,
      emailVerified,
    }
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default currentSession
