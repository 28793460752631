import { Auth } from 'aws-amplify'
import { ISignin } from '../../interface'
import localize from './localize'

const signin = async (user: ISignin) => {
  try {
    const res = await Auth.signIn(user.email, user.password)
    return { id: res.attributes, email: res.attributes.email }
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default signin
