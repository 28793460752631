import { Auth } from 'aws-amplify'
import { ISignup } from '../../interface'
import localize from './localize'

const signup = async (user: ISignup) => {
  try {
    const res = await Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        nickname: user.username,
      },
    })

    return { email: res.user.getUsername() }
  } catch (e) {
    e.message = localize(e.message)
    throw e
  }
}

export default signup
