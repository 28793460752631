export const QueryGetForecastList = `query getForecastList($isFavorite: Boolean, $first: Int, $after: String) {
  getForecastList(isFavorite: $isFavorite, first: $first, after: $after) {
    items {
      id,
      username,
      weather,
      value,
      reason,
      isFavorite,
      settedAt,
      createdAt,
      updatedAt,
    },
    nextToken
  }
}`
