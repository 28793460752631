import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { IForecastList } from './interface'
import { QueryGetForecastList } from './graphql'

export const getForecastList = async () => {
  const responseAll: any = await API.graphql({
    query: QueryGetForecastList,
    variables: { first: 999 },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  const forecastListAll = responseAll.data.getForecastList as IForecastList

  const responseFavorite: any = await API.graphql({
    query: QueryGetForecastList,
    variables: { first: 999, isFavorite: true },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  const forecastListFavorite = responseFavorite.data
    .getForecastList as IForecastList

  return { all: forecastListAll.items, favorite: forecastListFavorite.items }
}
