import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import { Modal, IconButton } from '@material-ui/core'
import Term from '@/pages/settings/term'

interface IOwnProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Modal
          open={props.open}
          className={classes.modal}
          disableBackdropClick={true}
        >
          <div className={classes.modalContainer}>
            <div className={classes.closeContainer}>
              <IconButton
                onClick={() => {
                  props.setOpen(false)
                }}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="2.00014"
                    y1="12.7284"
                    x2="13.3138"
                    y2="1.41466"
                    stroke="#B073E5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <line
                    x1="13.3137"
                    y1="12.7275"
                    x2="2"
                    y2="1.41383"
                    stroke="#B073E5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
            <Term />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      //
    },

    modal: {},

    modalContainer: {
      background: constants.BG_LIGHT_LIGHT,
      height: '100vh',
      padding: 20,
      overflow: 'scroll',
    },

    closeContainer: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
  })

export default withStyles(useStyles)(Index)
