import React from 'react'
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import * as constants from '@/assets/constants'
import * as pages from '@/assets/pages'
import { Modal, OutlinedInput, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import ModalAccountDelete from './ModalAccountDelete.component'
import { connector, ContainerProps } from './ModalEditProfile.container'
import { IInputProfile } from '@/stores/user/interface'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface IOwnProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type Props = IOwnProps &
  ContainerProps &
  RouteComponentProps &
  WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props
  const { handleSubmit, errors, register } = useForm<IInputProfile>()

  const onSubmit = (data: IInputProfile) => {
    props.updateProfile(data)
  }

  React.useEffect(() => {
    if (!props.user.processing && props.user.processed) {
      if (!props.user.emailVerified) {
        window.location.href = pages.VERIFY_EMAIL
        window.scrollTo(0, 0)
        props.setOpen(false)
      } else {
        props.setOpen(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.processing])

  const [openAccountDelete, setOpenAccountDelete] = React.useState(false)
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Modal
          open={props.open}
          className={classes.modal}
          disableBackdropClick={true}
        >
          <div className={classes.modalContainer}>
            <div
              className={`${classes.closeContainer} closeContainer`}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <div className="closeContainer__iconContainer">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 12L1 6.66622L7 1.33244"
                    stroke="#B0A1CB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="closeContainer__label">戻る</div>
            </div>
            <form
              className={`${classes.formContainer} formContainer`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="formContainer__formControl">
                <label>ユーザー名</label>
                <OutlinedInput
                  name="username"
                  placeholder="bedo"
                  defaultValue={props.user.username}
                  inputRef={register({
                    required: 'ユーザー名が入力されていません',
                  })}
                />

                <div className="formContainer__errorMessage">
                  {errors.username && errors.username.message}
                </div>
              </div>
              <div className="formContainer__formControl">
                <label>メールアドレス</label>
                <OutlinedInput
                  name="email"
                  placeholder="bedo@example.com"
                  defaultValue={props.user.email}
                  inputRef={register({
                    required: 'メールアドレスが入力されていません',
                  })}
                />

                <div className="formContainer__errorMessage">
                  {errors.email && errors.email.message}
                </div>
              </div>

              <div className={`${classes.buttonContianer} buttonContianer`}>
                <Button
                  className="buttonContianer__button"
                  type="submit"
                  disabled={props.user.processing}
                >
                  更新する
                </Button>
              </div>
            </form>

            <div
              className={classes.link}
              onClick={() => {
                setOpenAccountDelete(true)
              }}
            >
              アカウントの削除
            </div>
          </div>
        </Modal>

        <ModalAccountDelete
          open={openAccountDelete}
          setOpen={setOpenAccountDelete}
        />
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},

    modal: {},

    modalContainer: {
      background: constants.BG_LIGHT_LIGHT,
      height: '100vh',
    },

    closeContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 20,
      borderBottom: `1px solid #E1DEEB`,

      '& .closeContainer': {
        '&__iconContainer': {
          display: 'flex',
          alignItems: 'center',
          marginRight: 10,
        },

        '&__label': {},
      },
    },

    formContainer: {
      padding: 20,
      maxWidth: constants.BREAKPOINT_SMALL,
      margin: '0 auto',

      '& .formContainer': {
        '&__formControl': {
          '& label': {
            display: 'block',
            marginBottom: 10,
          },
          '& .MuiInputBase-root ': {
            width: '100%',
            height: 50,
            '& input': {
              background: constants.BG_LIGHT_DARK,
              fontSize: 14,
              '&::placeholder': {
                color: constants.COLOR_MAIN_LIGHT,
              },
            },
          },
        },
        '&__errorMessage': {
          padding: 5,
          height: 28,
          fontSize: 12,
          color: 'red',
        },
      },
    },
    buttonContianer: {
      '& .buttonContianer': {
        '&__button': {
          width: '100%',
          color: '#fff',
          height: 50,
          borderRadius: 25,

          background: 'linear-gradient(90deg, #746BFF 0%, #B073E5 100%)',
        },
      },
    },

    link: {
      textAlign: 'center',
      textDecoration: 'underline',
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
